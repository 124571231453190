import { DeleteForever, Edit, Subject } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";

export default function SubjectListItem({
  subject: t,
  onDelete,
  onEdit,
}: {
  subject: HbType.SubjectDataType;
  onDelete: () => void;
  onEdit: () => void;
}) {
  return (
    <ListItem disablePadding>
      <Paper
        sx={{
          margin: 0.3,
          width: "100%",
          minWidth: 200,
          // border: "1px solid black",
          padding: "4px 8px",
          borderRadius: 2,
          backgroundColor: Constants.colors.lightTheme.palette.background.paper,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          "&:hover": {
            // cursor:'pointer',
            backgroundColor: "#00000005",
          },
        }}
      >
        <Box>
          <Avatar
            sx={{
              backgroundColor:
                Constants.colors.lightTheme.palette.secondary.main,
              m: 2,
            }}
          >
            <Subject />
          </Avatar>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            <Typography
              variant="h6"
              color={Constants.colors.lightTheme.palette.text.primary}
            >
              {t.name}
            </Typography>
          </Box>
          <Box>
            <IconButton
              onClick={onEdit}
              size="medium"
              sx={{
                // borderRadius: 2,
                m: 0.3,
                backgroundColor:
                  Constants.colors.lightTheme.palette.primary.main,
                color: "white",
                "&:hover": {
                  color: Constants.colors.lightTheme.palette.primary.main,
                },
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={onDelete}
              size="medium"
              sx={{
                // borderRadius: 2,
                m: 0.3,
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  color: "red",
                },
              }}
            >
              <DeleteForever />
            </IconButton>
          </Box>
        </Box>
      </Paper>
    </ListItem>
  );
}
