import { DeleteForever } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { HbType } from "../../../utils/ConstType";
import Constants from "../../../utils/Constants";
import Api from "../../../utils/Api";
import { load } from "react-cookies";
export default function DialogDeleteClass({
  classs,
  onClose = () => {},
  onDeleted = () => {},
}: {
  classs: HbType.ClassDataType;
  onClose: () => void;
  onDeleted: () => void;
}) {
  // const [password, setPassword] = useState("");
  const [id, setId] = useState<number | null>(null);
  const [error, setError] = useState("");

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  useEffect(() => {
    if (id) {
      console.log("attempting to delete teacher by id: ", id);
      functionHandler.delete();
    }
  }, [id]);

  const functionHandler = {
    delete() {
      if (id) {
        Api.handler.classes.delete(id, loginToken)
        .then((res) =>{
          onDeleted();
        })
        .catch(e=>{

        });

      }
    },
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={true} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 4,
          gap: 2,
          width: "100%",
          minWidth: 200,
          fill: "Menu",
        }}
      >
        <Avatar
          sx={{
            backgroundColor: Constants.colors.lightTheme.palette.error.main,
          }}
        >
          <DeleteForever />
        </Avatar>

        <Typography>
          Are you sure? you want to delete following class :
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            color={Constants.colors.lightTheme.palette.primary.main}
            fontWeight="bold"
          >
            Name:
          </Typography>
          <Typography
            color={Constants.colors.lightTheme.palette.secondary.main}
          >
            {classs.name}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              // if (!password) {
              //   setError("Please enter your password");
              // } else {
              setId(classs.id);
              // }
            }}
          >
            Delete
          </Button>
          <Button variant="contained" color="warning" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
