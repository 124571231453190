import { Person } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { MD5 } from "crypto-js";
import { useMemo, useState } from "react";
import { load } from "react-cookies";
import Api from "../../../utils/Api";
import { ResponseType } from "../../../utils/ConstType";
import Constants from "../../../utils/Constants";
import LoadingView from "../../loading-view";

export default function DialogAddTeacher({
  onClose,
  onUpdate,
}: {
  onClose: () => void;
  onUpdate: () => void;
}) {
  const [info, _si] = useState<{
    status: boolean | null;
    message: string;
    visible: boolean;
  } | null>();

  const [itemAdded, setItemAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [caPassword, setCaPassword] = useState("");
  const [caVerifyPassword, setCaVerifyPassword] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneErro, setPhoneError] = useState(false);
  const [caPasswordError, setCaPasswordError] = useState(false);
  const [caVerifyPasswordError, setCaVerifyPasswordError] = useState(false);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  const functionHandler = {
    handleSnackbarClose() {
      _si(null);
    },
    setInfo(status: boolean | null, message: string) {
      _si({
        visible: true,
        status: status,
        message: message,
      });
    },
    handleAddTeacher(shouldHide: boolean) {
      setNameError(false);
      setEmailError(false);
      setPhoneError(false);
      setCaPasswordError(false);
      setCaVerifyPasswordError(false);
      if (!name || name.length === 0) {
        // alert("Campus name is required");
        setNameError(true);
        return;
      }
      const emailTester = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!email || !emailTester.test(String(email).toLowerCase())) {
        setEmailError(true);
        return;
      }
      if (!phone || phone.length <= 7) {
        setPhoneError(true);
        return;
      }
      if (!caPassword || caPassword.length <= 7) {
        setCaPasswordError(true);
        alert("password length must be greater than 8");
        return;
      }
      if (!caVerifyPassword || caVerifyPassword !== caPassword) {
        setCaVerifyPasswordError(true);
        return;
      }
      functionHandler.setInfo(null, "Adding campus details. Please wait...");
      setIsLoading(true);

      Api.handler.teachers
        .add(name, email, phone, MD5(caPassword).toString(), loginToken)
        .then((response) => {
          const apiResponse: ResponseType = response.data;
          if (apiResponse.status === "success") {
            if (shouldHide) {
              onUpdate();
            } else {
              setItemAdded(true);
              functionHandler.setInfo(
                true,
                "Teacher has been added successfully"
              );

              setName("");
              setEmail("");
              setPhone("");
              setCaPassword("");
              setCaVerifyPassword("");
            }
          } else {
            functionHandler.setInfo(false, apiResponse.message);
          }
        })
        .catch((e) => {
          console.log("error on creating campus", e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  return (
    <Dialog maxWidth="sm" fullWidth  open>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading && <LoadingView />}
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.2,
            width: "100%",
            minWidth: 200,
            // backgroundColor:'red',
            padding: 4,
            top: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Person sx={{ height: 32, width: 32 }} />
            <Typography
              variant="subtitle2"
              color={Constants.colors.lightTheme.palette.primary.main}
            >
              Add teacher :
            </Typography>
          </Box>
          <TextField
            variant="standard"
            label="Name"
            value={name}
            error={nameError}
            type="text"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            required
            label="Email"
            value={email}
            type="email"
            error={emailError}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            required
            label="Phone"
            value={phone}
            type="tel"
            error={phoneErro}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            type="password"
            required
            label="Password"
            value={caPassword}
            error={caPasswordError}
            onChange={(event) => {
              setCaPassword(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            type="password"
            required
            label="Verify password"
            value={caVerifyPassword}
            error={caVerifyPasswordError}
            onChange={(event) => {
              setCaVerifyPassword(event.target.value);
            }}
          />
          <Button
            sx={{ mt: 4 }}
            variant="contained"
            onClick={() => {
              functionHandler.handleAddTeacher(true);
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              functionHandler.handleAddTeacher(false);
            }}
          >
            Save and add next
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={itemAdded ? onUpdate : onClose}
          >
            Cancel
          </Button>
        </Paper>

        <Snackbar
          open={info?.visible}
          autoHideDuration={6000}
          onClose={functionHandler.handleSnackbarClose}
        >
          <Alert
            onClose={functionHandler.handleSnackbarClose}
            severity={
              info?.status === true
                ? "success"
                : info?.status === false
                ? "error"
                : "info"
            }
            sx={{ width: "100%" }}
          >
            {info?.message || "!!!"}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}
