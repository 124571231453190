import {  Add } from "@mui/icons-material";
import { Box, Button, List, TextField, Typography } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { load } from "react-cookies";
import LoadingView from "../../components/loading-view";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import Api from "../../utils/Api";
import { ResponseType } from "../../utils/ConstType";
import DialogAddGrade from "../../components/dashboard/fg-grade/dialog-add-grade";
import DialogEditGrade from "../../components/dashboard/fg-grade/dialog-edit-grade";
import GradeListItem from "../../components/dashboard/grade-list-item";
import DialogDeleteGrade from "../../components/dashboard/fg-grade/dialog-delete-grade";

export default function Grades(){
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [grades, setGrades] = useState<HbType.GradeDataType[]>([]);
    const [isAddGrade, setIsAddGrade] = useState(false);
    const [gradeInEditMode, setGradeInEditMode] =
      useState<HbType.GradeDataType | null>(null);
    const [gradeInDeleteMode, setGradeInDeleteMode] =
      useState<HbType.GradeDataType | null>(null);
  
    const loginToken = useMemo(
      () => load(Constants.key.CookiesKey.loginToken),
      []
    );
  
    const filteredGrades = useMemo(() => {
      let sft = grades;
      if (searchQuery.length > 0) {
        const sq = (searchQuery || "").trim().toLowerCase();
  
        sft = sft.filter((o) => {
          if (
            (o.name || "").trim().toLowerCase().includes(sq)
          ) {
            return true;
          }
  
          return false;
        });
      }
  
      return sft;
    }, [grades, searchQuery]);
  
    useEffect(() => {
      functionHandler.getAllGrade();
    }, []);
  
    const functionHandler = {
      getAllGrade() {
        setIsLoading(true);
        Api.handler.grades
          .all(loginToken)
          .then((response) => {
            // console.log("response is: ", response.data);
            const result = response.data as ResponseType;
            const tsrs = result.message as HbType.GradeDataType[];
            setGrades(tsrs);
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false);
          });
      },
    };
  
    return (
      <Box>
        {isLoading && <LoadingView />}
        {isAddGrade && (
          <DialogAddGrade
            onClose={() => setIsAddGrade(false)}
            onUpdate={() => {
              setIsAddGrade(false);
              functionHandler.getAllGrade();
            }}
          />
        )}
        {gradeInEditMode && (
          <DialogEditGrade
            grade={gradeInEditMode}
            onClose={() => setGradeInEditMode(null)}
            onUpdate={() => {
              setGradeInEditMode(null);
              functionHandler.getAllGrade();
            }}
          />
        )}
        {gradeInDeleteMode && (
          <DialogDeleteGrade
            grade={gradeInDeleteMode}
            onClose={() => setGradeInDeleteMode(null)}
            onDeleted={() => {
              setGradeInDeleteMode(null);
              functionHandler.getAllGrade();
            }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
            flexWrap: "wrap",
            marginTop: 0.8,
            // height: 56,
            overflow: "hidden",
          }}
        >
          <Button
            sx={{ height: "100%" }}
            variant="contained"
            color="success"
            startIcon={<Add />}
            onClick={() => setIsAddGrade(true)}
          >
            Add Grade
          </Button>
          <TextField
            sx={{ flexGrow: 1 }}
            placeholder="Search..."
            variant="standard"
            type="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
  
        <List>
          {/* grade lists */}
          {filteredGrades.length > 0 &&
            filteredGrades.map((t) => (
              <GradeListItem
                grade={t}
                onDelete={() => {setGradeInDeleteMode(t)}}
                onEdit={() => setGradeInEditMode(t)}
                key={t.id}
              />
            ))}
        </List>
      </Box>
    );
}