/* eslint-disable jsx-a11y/img-redundant-alt */
import { AddAPhoto } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { load } from "react-cookies";
import Api from "../../../utils/Api";
import { HbType, ResponseType } from "../../../utils/ConstType";
import Constants from "../../../utils/Constants";
import Utils from "../../../utils/utils";
import LoadingView from "../../loading-view";
import { HBImageTint } from "../../temp-ef-image-root";
import { MD5 } from "crypto-js";

export default function DialogEditTeacher({
  teacher,
  onClose,
  onUpdate,
}: {
  teacher: HbType.TeacherDataType;
  onClose: () => void;
  onUpdate: () => void;
}) {
  const [info, _si] = useState<{
    status: boolean | null;
    message: string;
    visible: boolean;
  } | null>();

  const [itemAdded, setItemAdded] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [caPassword, setCaPassword] = useState("");
  const [caVerifyPassword, setCaVerifyPassword] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneErro, setPhoneError] = useState(false);
  const [caPasswordError, setCaPasswordError] = useState(false);
  const [caVerifyPasswordError, setCaVerifyPasswordError] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [profileImageUploadableURl, setProfileImageUploadableUrl] =
    useState<File | null>(null);

  const inputFileRef = useRef(null);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  useEffect(() => {
    setName(teacher.name);
    setPhone(teacher.phone);
    setEmail(teacher.email);
    setProfileImageUrl(Utils.retrieveImageURL(teacher.profile_url));
  }, []);

  useEffect(() => {
    if (profileImageUploadableURl) {
      functionHandler.uploadProfile();
    }
  }, [profileImageUploadableURl]);

  const functionHandler = {
    handleSnackbarClose() {
      _si(null);
    },
    setInfo(status: boolean | null, message: string) {
      _si({
        visible: true,
        status: status,
        message: message,
      });
    },
    handleUpdateTeacher() {
      setNameError(false);
      setEmailError(false);
      setPhoneError(false);
      setCaPasswordError(false);
      setCaVerifyPasswordError(false);
      if (!name || name.length === 0) {
        // alert("Campus name is required");
        setNameError(true);
        return;
      }
      const emailTester = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!email || !emailTester.test(String(email).toLowerCase())) {
        setEmailError(true);
        return;
      }
      // if (!caPassword || caPassword.length <= 7) {
      //   setCaPasswordError(true);
      //   alert("password length must be greater than 8");
      //   return;
      // }
      if (caPassword && (!caVerifyPassword || caVerifyPassword !== caPassword)) {
        setCaVerifyPasswordError(true);
        return;
      }
      functionHandler.setInfo(null, "Uploading data. Please wait...");
      setIsLoading(true);

      Api.handler.teachers
        .update(teacher.id, name, email,phone, MD5(caPassword).toString(), loginToken)
        .then((response) => {
          const apiResponse: ResponseType = response.data;
          if (apiResponse.status === "success") {
            onUpdate();

            // if (shouldHide) {
            //   onUpdate();
            // } else {
            //   setItemAdded(true);
            //   functionHandler.setInfo(
            //     true,
            //     "Teacher has been added successfully"
            //   );

            //   setName("");
            //   setEmail("");
            //   setCaPassword("");
            //   setCaVerifyPassword("");
            // }
          } else {
            functionHandler.setInfo(false, apiResponse.message);
          }
        })
        .catch((e) => {
          console.log("error on creating campus", e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    onImagePicked(event: React.ChangeEvent<HTMLInputElement>) {
      const files = event.target.files;
      if (files && files?.length > 0) {
        const purl = files[0];
        setProfileImageUploadableUrl(purl);

        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
          console.log("reader result is: ", reader.result);
          setProfileImageUrl(reader.result as any);
        });
        reader.readAsDataURL(purl);
      }
    },
    uploadProfile() {
      try {
        if (profileImageUploadableURl !== null) {
          // const spl = profileImageUploadableURl?.name.split(".");
          // const a = spl ? spl[(spl?.length || 0) - 1] : "png";

          setIsLoading(true);

          const name = Utils.getTeacherProfileImage(
            teacher.campus_id,
            teacher.id
          );
          const formData = new FormData();
          formData.append("photo", profileImageUploadableURl);

          Api.handler
            .uploadPhoto(formData, name, loginToken)
            .then((response) => {
              functionHandler.setInfo(true, "Profile image uploaded");

              console.log("file uploaded. response: ", response.data);
            })
            .catch((error) => {
              setProfileImageUrl(Utils.retrieveImageURL(teacher.profile_url));
              setProfileImageUploadableUrl(null);

              functionHandler.setInfo(
                false,
                "failed to upload photo. reason: " + error
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } catch (e) {}
    },
  };

  // console.log("upload task: ", profileImageUploadableURl);
  // functionHandler.uploadProfile();

  return (
    <Dialog maxWidth="sm" fullWidth  open>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading && <LoadingView />}
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.2,
            width: "100%",
            minWidth: 200,
            // backgroundColor:'red',
            padding: 4,
            top: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              color={Constants.colors.lightTheme.palette.primary.main}
            >
              Edit Teacher
            </Typography>
            <Avatar sx={{ width: 100, height: 100, overflow: "visible" }}>
              <Avatar sx={{ width: "100%", height: "100%" }}>
                {profileImageUrl ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={profileImageUrl}
                    alt="profile iamge"
                  />
                ) : (
                  <HBImageTint
                    color={Constants.colors.lightTheme.palette.text.onPrimary}
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={Constants.images.teacher}
                    alt="profile image"
                  />
                )}
              </Avatar>
              <IconButton
                size="large"
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  width: "100%",
                  height: "100%",
                  border: "1px solid white",
                  backgroundColor: "transparent",
                  color: "transparent",
                  "&:hover": {
                    backgroundColor:
                      Constants.colors.lightTheme.palette.primary.main + "d0",
                    color: Constants.colors.lightTheme.palette.text.onPrimary,
                  },
                }}
                onClick={() => (inputFileRef?.current as any).click()}
              >
                <AddAPhoto sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Avatar>
          </Box>

          <input
            ref={inputFileRef}
            name="upp"
            type="file"
            accept="image/*"
            title="Choose profile image"
            onChange={functionHandler.onImagePicked}
            style={{ display: "none" }}
          />

          <TextField
            variant="standard"
            label="Name"
            value={name}
            error={nameError}
            type="text"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            required
            label="Email"
            value={email}
            type="email"
            error={emailError}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            required
            label="Phone"
            value={phone}
            type="tel"
            error={phoneErro}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />
          <Typography
            variant="subtitle2"
            mt={1}
            sx={{
              color: Constants.colors.lightTheme.palette.secondary.main,
            }}
          >
            Change old password (optional)
          </Typography>
          <TextField
            variant="standard"
            type="password"
            label="New password"
            value={caPassword}
            error={caPasswordError}
            onChange={(event) => {
              setCaPassword(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            type="password"
            label="Verify password"
            value={caVerifyPassword}
            error={caVerifyPasswordError}
            onChange={(event) => {
              setCaVerifyPassword(event.target.value);
            }}
          />
          <Button
            sx={{ mt: 4 }}
            variant="contained"
            onClick={() => {
              functionHandler.handleUpdateTeacher();
              //edit teacher
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={itemAdded ? onUpdate : onClose}
          >
            Cancel
          </Button>
        </Paper>

        <Snackbar
          open={info?.visible}
          autoHideDuration={6000}
          onClose={functionHandler.handleSnackbarClose}
        >
          <Alert
            onClose={functionHandler.handleSnackbarClose}
            severity={
              info?.status === true
                ? "success"
                : info?.status === false
                ? "error"
                : "info"
            }
            sx={{ width: "100%" }}
          >
            {info?.message || "!!!"}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}
