import { Book } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { load } from "react-cookies";
import Api from "../../../utils/Api";
import { ResponseType } from "../../../utils/ConstType";
import Constants from "../../../utils/Constants";
import LoadingView from "../../loading-view";

export default function DialogAddSubject({
  onClose,
  onUpdate,
}: {
  onClose: () => void;
  onUpdate: () => void;
}) {
  const [info, _si] = useState<{
    status: boolean | null;
    message: string;
    visible: boolean;
  } | null>();

  const [itemAdded, setItemAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  const functionHandler = {
    handleSnackbarClose() {
      _si(null);
    },
    setInfo(status: boolean | null, message: string) {
      _si({
        visible: true,
        status: status,
        message: message,
      });
    },
    handleAddSubject(shouldHide: boolean) {
      setNameError(false);
      if (!name || name.length === 0) {
        // alert("Campus name is required");
        setNameError(true);
        return;
      }

      functionHandler.setInfo(null, "Adding subject. Please wait...");
      setIsLoading(true);

      Api.handler.subjects
        .add(name, loginToken)
        .then((response) => {
          const apiResponse: ResponseType = response.data;
          if (apiResponse.status === "success") {
            if (shouldHide) {
              onUpdate();
            } else {
              setItemAdded(true);
              functionHandler.setInfo(
                true,
                "Subject has been added successfully"
              );

              setName("");
            }
          } else {
            functionHandler.setInfo(false, apiResponse.message);
          }
        })
        .catch((e) => {
          console.log("error on creating campus", e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  return (
    <Dialog maxWidth="sm" fullWidth  open>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading && <LoadingView />}
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.2,
            width: "100%",
            minWidth: 200,
            // backgroundColor:'red',
            padding: 4,
            top: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Book sx={{ height: 32, width: 32 }} />
            <Typography
              variant="subtitle2"
              color={Constants.colors.lightTheme.palette.primary.main}
            >
              Add subject :
            </Typography>
          </Box>
          <TextField
            variant="standard"
            label="Name"
            value={name}
            error={nameError}
            type="text"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <Button
            sx={{ mt: 4 }}
            variant="contained"
            onClick={() => {
              functionHandler.handleAddSubject(true);
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              functionHandler.handleAddSubject(false);
            }}
          >
            Save and add next
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={itemAdded ? onUpdate : onClose}
          >
            Cancel
          </Button>
        </Paper>

        <Snackbar
          open={info?.visible}
          autoHideDuration={6000}
          onClose={functionHandler.handleSnackbarClose}
        >
          <Alert
            onClose={functionHandler.handleSnackbarClose}
            severity={
              info?.status === true
                ? "success"
                : info?.status === false
                ? "error"
                : "info"
            }
            sx={{ width: "100%" }}
          >
            {info?.message || "!!!"}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}
