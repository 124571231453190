import Api from "./Api";
import { loginType } from "./ConstType";

const Utils = {
  isValidLoginType: (type: loginType | undefined) => {
    if (!type) return false;
    if (
      type === "admin" ||
      type === "campusAdmin" ||
      type === "parents" ||
      type === "student" ||
      type === "teacher"
    ) {
      return true;
    }

    return false;
  },
  getTeacherProfileImage(campusId: number, teacherId: number) {
    return "teacher_" + campusId + "_" + teacherId;
  },
  getStudentProfileImage(campusId: number, studentId: number) {
    return "student_" + campusId + "_" + studentId;
  },
  retrieveImageURL(name:string){
    return `${Api.endpoints.uploads}${name}`;
  }
};

export default Utils;
