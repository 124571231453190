import { Logout, Person, School } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { save } from "react-cookies";
import { useNavigate } from "react-router-dom";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";

export default function AccountActionTooltip({
  onHide,
  user,
}: {
  onHide: () => void;
  user: HbType.CampusDataType;
}) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 99,
        right: 0,
        top: 0,
        left: 0,
        bottom: 0,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          position: "absolute",
          //   backgroundColor: "red",
          zIndex: 0,
        }}
        onClick={onHide}
      />
      <Box>
        <Toolbar></Toolbar>
        <Box
          sx={{
            backgroundColor:
              Constants.colors.lightTheme.palette.background.default,
            borderRadius: 2,
            boxShadow: "0px 0px 5px 0px",
            padding: 0,
            zIndex: 1,
            position: "absolute",
            right: 0,
            marginRight: 1.4,
          }}
        >
          <Box>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: "16px 32px",
              }}
            >
              <IconButton
                sx={{
                  position: "relative",
                  p: 1.2,
                  borderRadius: "50%",
                  border: "1px solid #0000000f",
                  backgroundColor: "#0000000f",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: -6,
                    right: -12,
                    p: "4px 8px",
                    borderRadius: "50%",
                    border: "1px solid #0000000f",
                    backgroundColor: "#b7e5f0",
                    zIndex: 1,
                  }}
                >
                  <School
                    sx={{
                      // height: 32,
                      // width: 32,
                      color: Constants.colors.lightTheme.palette.primary.main,
                    }}
                  />
                </Box>
                <Person
                  sx={{
                    height: 58,
                    width: 58,
                    color: Constants.colors.lightTheme.palette.primary.main,
                  }}
                />
              </IconButton>
              <Typography variant="h6">{user.ca_name}</Typography>
              <Typography variant="subtitle1">{user.email}</Typography>
              <Typography
                color={Constants.colors.lightTheme.palette.secondary.main}
                variant="subtitle2"
              >
                (Admin at)
              </Typography>
              <Typography
                color={Constants.colors.lightTheme.palette.primary.main}
                variant="h6"
              >
                {user.name}
              </Typography>
              <Typography
                color={Constants.colors.lightTheme.palette.secondary.main}
                variant="subtitle1"
              >
                {user.description}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <List sx={{ mr: 0, p: 0 }}>
            <ListItem sx={{ mr: 0, p: 0 }}>
              <ListItemButton
                sx={{ mr: 0, p: "8px 16px" }}
                onClick={() => {
                  save(Constants.key.CookiesKey.loginToken, "", {
                    maxAge: 3600 * 24 * 60,
                  });
                  navigate(Constants.navigationStack.login);
                }}
              >
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
}
