import { Add, UploadFile } from "@mui/icons-material";
import {
  Box,
  Button,
  List,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { load } from "react-cookies";
import DialogAddStudent from "../../components/dashboard/fg-student/dialog-add-student";
import DialogEditStudent from "../../components/dashboard/fg-student/dialog-edit-student";
import DialogUploadStudent from "../../components/dashboard/fg-student/dialog-upload-student";
import StudentListItem from "../../components/dashboard/student-list-item";
import LoadingView from "../../components/loading-view";
import Api from "../../utils/Api";
import { HbType, ResponseType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import DialogDeleteStudent from "../../components/dashboard/fg-student/dialog-delete-student";

export default function Students() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [students, setStudents] = useState<HbType.StudentDataType[]>([]);
  const [isAddStudent, setIsAddStudent] = useState(false);
  const [isUploadStudent, setIsUploadStudent] = useState(false);
  const [studentInEditMode, setStudentInEditMode] =
    useState<HbType.StudentDataType | null>(null);
  const [studentInDeleteMode, setStudentInDeleteMode] =
    useState<HbType.StudentDataType | null>(null);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  const filteredStudents = useMemo(() => {
    let sft = students;
    if (searchQuery.length > 0) {
      const sq = (searchQuery || "").trim().toLowerCase();

      sft = sft.filter((o) => {
        if (
          (o.first_name + " " + o.middle_name + " " + o.last_name || "")
            .trim()
            .toLowerCase()
            .includes(sq) ||
          (o.email || "").trim().toLowerCase().includes(sq) ||
          (o.phone || "").trim().toLowerCase().includes(sq) ||
          (o.gradeName || "").trim().toLowerCase().includes(sq)
        ) {
          return true;
        }

        return false;
      });
    }

    return sft;
  }, [students, searchQuery]);

  // pagination

  const [paginLimit, setPaginLimit] = useState(10);
  const [paginCurrentPage, setPaginCurrentPage] = useState(1);
  const paginationRootRef = useRef(null);
  const paginationFooterRef = useRef(null);

  const paginPages = useMemo(() => {
    return Math.ceil(filteredStudents.length / paginLimit);
  }, [paginLimit, filteredStudents]);

  const footerContent = useMemo(() => {
    const buttons = [];

    for (var i = paginCurrentPage - 2; i < paginCurrentPage + 3; i++) {
      if (i === paginCurrentPage) {
        buttons.push(
          <Button variant="contained" color="info" disabled>
            {i}
          </Button>
        );
      } else if (i > 0 && i <= paginPages) {
        const x = i;
        buttons.push(
          <Button
            variant="contained"
            color="warning"
            onClick={() => setPaginCurrentPage(x)}
          >
            {i}
          </Button>
        );
      }
    }

    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          fontSize: "22px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setPaginCurrentPage(1)}
        >
          {"< First"}
        </Button>

        {buttons}

        <Button
          variant="contained"
          color="primary"
          onClick={() => setPaginCurrentPage(paginPages)}
        >
          {"Last >"}
        </Button>
      </Box>
    );
  }, [paginCurrentPage, paginPages]);

  //pagination

  useEffect(() => {
    functionHandler.getAllStudent();
  }, []);

  const functionHandler = {
    getAllStudent() {
      setIsLoading(true);
      Api.handler.students
        .all(loginToken)
        .then((response) => {
          // console.log("response is: ", response.data);
          const result = response.data as ResponseType;
          const tsrs = result.message as HbType.StudentDataType[];
          setStudents(tsrs);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  return (
    <Box>
      {isLoading && <LoadingView />}
      {isAddStudent && (
        <DialogAddStudent
          onClose={() => setIsAddStudent(false)}
          onUpdate={() => {
            setIsAddStudent(false);
            functionHandler.getAllStudent();
          }}
        />
      )}
      {isUploadStudent && (
        <DialogUploadStudent
          onClose={() => setIsUploadStudent(false)}
          onUpdate={() => {
            setIsUploadStudent(false);
            functionHandler.getAllStudent();
          }}
        />
      )}
      {studentInEditMode && (
        <DialogEditStudent
          student={studentInEditMode}
          onClose={() => setStudentInEditMode(null)}
          onUpdate={() => {
            setStudentInEditMode(null);
            functionHandler.getAllStudent();
          }}
        />
      )}
      {studentInDeleteMode && (
        <DialogDeleteStudent
          student={studentInDeleteMode}
          onClose={() => setStudentInDeleteMode(null)}
          onDeleted={() => {
            setStudents((o) =>
              o.filter((x) => x.id !== studentInDeleteMode.id)
            );
            setStudentInDeleteMode(null);
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          gap: 0.5,
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          marginTop: 0.8,
          // height: 56,
          overflow: "hidden",
        }}
      >
        <Button
          sx={{ height: "100%" }}
          variant="contained"
          color="success"
          startIcon={<Add />}
          onClick={() => setIsAddStudent(true)}
        >
          Add Student
        </Button>
        <Button
          sx={{ height: "100%" }}
          variant="contained"
          color="secondary"
          startIcon={<UploadFile />}
          onClick={() => setIsUploadStudent(true)}
        >
          Upload
        </Button>
        <TextField
          sx={{ flexGrow: 1 }}
          placeholder="Search..."
          variant="standard"
          type="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      <Box
        ref={paginationRootRef}
        pt={1}
        sx={{
          height: `calc(100vh - ${
            (paginationRootRef.current || ({ offsetTop: 0 } as any)).offsetTop +
            64
          }px)`,
        }}
      >
        <Box
          sx={{
            height: "100%",
            border: 3,
            borderRadius: 2,
            borderColor:
              Constants.colors.lightTheme.palette.text.secondary + "35",
            p: 1,
            m: 0,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",

            // display: flex;
            // justify-content: center;
            // align-items: center;
            // flex-direction: column;
          }}
        >
          <Box
            sx={{
              // height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              p: 1,
            }}
          >
            <Select
              autoWidth
              variant="outlined"
              size="small"
              value={paginLimit}
              onChange={(e) => setPaginLimit(Number(e.target.value))}
            >
              {[10, 25, 50, 100, 250].map((g) => (
                <MenuItem key={g} value={g}>
                  {g}
                </MenuItem>
              ))}
            </Select>
            <Typography> &nbsp;Record per page.</Typography>
          </Box>

          <Box
            sx={{
              // flex:1,
              // alignSelf: "stretch",
              boxSizing: "border-box",
              MozBoxSizing: "border-box",
              WebkitBoxSizing: "border-box",
              height: "100%",
              width: "100%",
              overflow: "auto",
              // backgroundColor: "red",

              // overflowY: "hidden",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                // backgroundColor: "yellow",
              }}
            >
              {filteredStudents.length > 0 &&
                Array.apply(null, { length: paginLimit } as any)
                  .map(Number.call, Number)
                  .map(
                    (n: any) =>
                      filteredStudents.length >
                        n + paginLimit * paginCurrentPage - paginLimit && (
                        //               <StudentListItem
                        //   student={t}
                        //   onDelete={() => { }}
                        //   onEdit={() => setStudentInEditMode(t)}
                        //   key={t.id}
                        // />
                        <StudentListItem
                          key={n}
                          student={
                            filteredStudents[
                              n + paginLimit * paginCurrentPage - paginLimit
                            ]
                          }
                          onDelete={() =>
                            setStudentInDeleteMode(
                              filteredStudents[
                                n + paginLimit * paginCurrentPage - paginLimit
                              ]
                            )
                          }
                          onEdit={() =>
                            setStudentInEditMode(
                              filteredStudents[
                                n + paginLimit * paginCurrentPage - paginLimit
                              ]
                            )
                          }
                        />
                      )
                  )}
            </Box>
          </Box>

          <Box
            ref={paginationFooterRef}
            sx={{
              width: "100%",
              padding: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              // backgroundColor: "blue",
            }}
          >
            {footerContent}
          </Box>
        </Box>
      </Box>

      {/* <List>
        {/* student lists *\/}
        {filteredStudents && filteredStudents.map &&
          filteredStudents.map((t) => (
            <StudentListItem
              student={t}
              onDelete={() => { }}
              onEdit={() => setStudentInEditMode(t)}
              key={t.id}
            />
          ))}
      </List> */}
    </Box>
  );
}
