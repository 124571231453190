import { Edit, House, Settings } from "@mui/icons-material";
import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import { load, save } from "react-cookies";
import { useNavigate } from "react-router-dom";
import AccountActionTooltip from "../components/dashboard/account-action-tooltip";
import DashboardDrawer from "../components/dashboard/drawer";
import HeaderBar from "../components/header-bar";
import LoadingView from "../components/loading-view";
import { HBImageTint } from "../components/temp-ef-image-root";
import Calendars from "../fragments/dashboard/fg-dashboard-calendar";
import Classes from "../fragments/dashboard/fg-dashboard-classes";
import Grades from "../fragments/dashboard/fg-dashboard-grades";
import Students from "../fragments/dashboard/fg-dashboard-student";
import Subjects from "../fragments/dashboard/fg-dashboard-subjects";
import Supervisor from "../fragments/dashboard/fg-dashboard-supervisor";
import Teachers from "../fragments/dashboard/fg-dashboard-teachers";
import Api from "../utils/Api";
import { HbType, ResponseType } from "../utils/ConstType";
import Constants from "../utils/Constants";
//cookie.load("login-type");

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [me, setMe] = useState<HbType.CampusDataType>({
    name: "N/A",
    email: "N/A",
    ca_name: "N/A",
    description: "N/A",
    id: -1,
  });
  const [activeFragment, setActiveFragment] = useState<
    | "teachers"
    | "grades"
    | "students"
    | "classes"
    | "subjects"
    | "events"
    | "supervisor"
    | "edit-accounts"
    | "settings"
  >("teachers");
  const [title, setTitle] = useState("Dashboard");

  const drawerItem = [
    {
      groupTitle: "Campus area",
      items: [
        {
          title: "Teachers",
          onClick: () => {
            setActiveFragment("teachers");
          },
          iconComponent: (
            <HBImageTint
              src={Constants.images.teacher}
              color={Constants.colors.lightTheme.palette.primary.main}
              sx={{
                width: 24,
                height: 24,
              }}
            />
          ),
        },
        {
          title: "Grades",
          onClick: () => {
            setActiveFragment("grades");
          },
          iconComponent: (
            <HBImageTint
              src={Constants.icons.grading}
              color={Constants.colors.lightTheme.palette.primary.main}
              sx={{
                width: 24,
                height: 24,
              }}
            />
          ),
        },
        {
          title: "Students",
          onClick: () => {
            setActiveFragment("students");
          },
          iconComponent: (
            <HBImageTint
              src={Constants.images.student}
              color={Constants.colors.lightTheme.palette.primary.main}
              sx={{
                width: 24,
                height: 24,
              }}
            />
          ),
        },
        {
          title: "Classes",
          onClick: () => {
            setActiveFragment("classes");
          },
          iconComponent: (
            <House
              sx={{ color: Constants.colors.lightTheme.palette.primary.main }}
            />
            // <HBImageTint
            //   src={Constants.icons.grading}
            //   color={Constants.colors.lightTheme.palette.primary.main}
            //   sx={{
            //     width: 24,
            //     height: 24,
            //   }}
            // />
          ),
        },
        {
          title: "Subjects",
          onClick: () => {
            setActiveFragment("subjects");
          },
          iconComponent: (
            <HBImageTint
              src={Constants.icons.classNotes}
              color={Constants.colors.lightTheme.palette.primary.main}
              sx={{
                width: 24,
                height: 24,
              }}
            />
          ),
        },
        {
          title: "Supervisor",
          onClick: () => {
            setActiveFragment("supervisor");
          },
          iconComponent: (
            <HBImageTint
              src={Constants.images.teacher}
              color={Constants.colors.lightTheme.palette.primary.main}
              sx={{
                width: 24,
                height: 24,
              }}
            />
          ),
        },
        {
          title: "Events",
          onClick: () => {
            setActiveFragment("events");
          },
          iconComponent: (
            <HBImageTint
              src={Constants.icons.calendar}
              color={Constants.colors.lightTheme.palette.primary.main}
              sx={{
                width: 24,
                height: 24,
              }}
            />
          ),
        },
      ],
    },
    {
      groupTitle: "Account",
      items: [
        {
          title: "Edit account",
          onClick: () => {
            setActiveFragment("edit-accounts");
          },
          iconComponent: (
            <Edit
              sx={{ color: Constants.colors.lightTheme.palette.primary.main }}
            />
          ),
        },
        {
          title: "Settings",
          onClick: () => {
            setActiveFragment("settings");
          },
          iconComponent: (
            <Settings
              sx={{ color: Constants.colors.lightTheme.palette.primary.main }}
            />
          ),
        },
      ],
    },
  ];

  const navigate = useNavigate();
  React.useEffect(() => {
    document.title = "Dashboard - Campus Admin";

    const loginToken = load(Constants.key.CookiesKey.loginToken);

    // alert(loginToken);

    if (loginToken) {
      Api.handler
        .validate(loginToken)
        .then((response) => {
          const apiResponse: ResponseType = response.data;

          if (apiResponse.status === "success") {
            console.log("valid token: ", apiResponse);
            save(Constants.key.CookiesKey.campusInfo, apiResponse.message, {
              maxAge: 3600 * 24 * 60,
            });
            setMe(apiResponse.message);
            setIsLoading(false);
          } else {
            alert("Error while logging in.");
            navigate(Constants.navigationStack.login);
          }

          // if (response.data) {
          //   setIsLoading(false);
          // } else {
          // }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      navigate(Constants.navigationStack.login);
    }
  }, []);

  const uiContent = () => {
    switch (activeFragment) {
      case "teachers":
        return <Teachers />;
      case "students":
        return <Students />;
      case "grades":
        return <Grades />;
      case "classes":
        return <Classes />;
      case "subjects":
        return <Subjects />;
      case "supervisor":
        return <Supervisor />;
      case "events":
        return <Calendars />;
      case "edit-accounts":
        return <></>;
      case "settings":
        return <></>;
    }
  };

  return (
    <Box>
      {isLoading && <LoadingView />}
      <HeaderBar
        title={title}
        onMenuClick={() => {
          setIsDrawerOpen((o) => !o);
        }}
        onUserNameClick={() => setShowTooltip(true)}
        userName={me.ca_name}
      />
      <DashboardDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        drawerItem={drawerItem}
        setTitle={setTitle}
      />
      {showTooltip && (
        <AccountActionTooltip user={me} onHide={() => setShowTooltip(false)} />
      )}

      <Container>{uiContent()}</Container>
    </Box>
  );
};

export default Dashboard;
