/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { load } from "react-cookies";
import Api from "../../../utils/Api";
import { HbType, ResponseType } from "../../../utils/ConstType";
import Constants from "../../../utils/Constants";
import Utils from "../../../utils/utils";
import LoadingView from "../../loading-view";
import { HBImageTint } from "../../temp-ef-image-root";

export default function DialogChooseSuepervisor({
  teacher,
  onUpdate,
  onClose,
}: {
  teacher: HbType.TeacherDataType;
  onUpdate: () => void;
  onClose: () => void;
}) {
  const [info, _si] = useState<{
    status: boolean | null;
    message: string;
    visible: boolean;
  } | null>();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState("");

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  useEffect(() => {
    setName(teacher.name);
    setPhone(teacher.phone);
    setEmail(teacher.email);
    setProfileImageUrl(Utils.retrieveImageURL(teacher.profile_url));
  }, []);

  const functionHandler = {
    handleSnackbarClose() {
      _si(null);
    },
    setInfo(status: boolean | null, message: string) {
      _si({
        visible: true,
        status: status,
        message: message,
      });
    },

    handleSetSupervisor() {
      setIsLoading(true);
      Api.handler.supervisor
        .set(teacher.id, loginToken)
        .then((res) => {
          const response = res.data as ResponseType;

          if (response.status === "success") {
            onUpdate();
            return;
          }

          alert("Failed to set supervisor. Error: " + response.message);
        })
        .catch((e) => alert("Failed to set supervisor. Error: " + e))
        .finally(() => setIsLoading(false));
      // onUpdate();
    },

    // handleAddTeacher(shouldHide: boolean) {
    //   setNameError(false);
    //   setEmailError(false);
    //   setPhoneError(false);
    //   setCaPasswordError(false);
    //   setCaVerifyPasswordError(false);
    //   if (!name || name.length === 0) {
    //     // alert("Campus name is required");
    //     setNameError(true);
    //     return;
    //   }
    //   const emailTester = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    //   if (!email || !emailTester.test(String(email).toLowerCase())) {
    //     setEmailError(true);
    //     return;
    //   }
    //   if (!caPassword || caPassword.length <= 7) {
    //     setCaPasswordError(true);
    //     alert("password length must be greater than 8");
    //     return;
    //   }
    //   if (!caVerifyPassword || caVerifyPassword !== caPassword) {
    //     setCaVerifyPasswordError(true);
    //     return;
    //   }
    //   functionHandler.setInfo(null, "Uploading data. Please wait...");
    //   setIsLoading(true);

    //   Api.handler.teachers
    //     .add(name, email,phone, caPassword, loginToken)
    //     .then((response) => {
    //       const apiResponse: ResponseType = response.data;
    //       if (apiResponse.status === "success") {
    //         if (shouldHide) {
    //           onUpdate();
    //         } else {
    //           setItemAdded(true);
    //           functionHandler.setInfo(
    //             true,
    //             "Teacher has been added successfully"
    //           );

    //           setName("");
    //           setEmail("");
    //           setCaPassword("");
    //           setCaVerifyPassword("");
    //         }
    //       } else {
    //         functionHandler.setInfo(false, apiResponse.message);
    //       }
    //     })
    //     .catch((e) => {
    //       console.log("error on creating campus", e);
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //     });
    // },
  };

  // console.log("upload task: ", profileImageUploadableURl);
  // functionHandler.uploadProfile();

  return (
    <Dialog maxWidth="sm" fullWidth  open>
      {isLoading && <LoadingView />}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.2,
            width: "100%",
            minWidth: 200,
            // backgroundColor:'red',
            padding: 4,
            top: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              color={Constants.colors.lightTheme.palette.primary.main}
            >
              Set Supervisor
            </Typography>
            <Avatar sx={{ width: 100, height: 100, overflow: "visible" }}>
              <Avatar sx={{ width: "100%", height: "100%" }}>
                {profileImageUrl ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={profileImageUrl}
                    alt="profile iamge"
                  />
                ) : (
                  <HBImageTint
                    color={Constants.colors.lightTheme.palette.text.onPrimary}
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={Constants.images.teacher}
                    alt="profile image"
                  />
                )}
              </Avatar>
              {/* <IconButton
                size="large"
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  width: "100%",
                  height: "100%",
                  border: "1px solid white",
                  backgroundColor: "transparent",
                  color: "transparent",
                  "&:hover": {
                    backgroundColor:
                      Constants.colors.lightTheme.palette.primary.main + "d0",
                    color: Constants.colors.lightTheme.palette.text.onPrimary,
                  },
                }}
                onClick={() => (inputFileRef?.current as any).click()}
              >
                <AddAPhoto sx={{ width: 32, height: 32 }} />
              </IconButton> */}
            </Avatar>
          </Box>

          <TextField
            variant="standard"
            label="Name"
            disabled
            value={name}
            type="text"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            required
            label="Email"
            value={email}
            disabled
            type="email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            required
            label="Phone"
            disabled
            value={phone}
            type="tel"
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />
          <Button
            sx={{ mt: 4 }}
            variant="contained"
            onClick={() => {
              // functionHandler.handleAddTeacher(true);
              //edit teacher
              functionHandler.handleSetSupervisor();
            }}
          >
            Set
          </Button>
          <Button variant="contained" color="error" onClick={onClose}>
            Cancel
          </Button>
        </Paper>

        <Snackbar
          open={info?.visible}
          autoHideDuration={6000}
          onClose={functionHandler.handleSnackbarClose}
        >
          <Alert
            onClose={functionHandler.handleSnackbarClose}
            severity={
              info?.status === true
                ? "success"
                : info?.status === false
                ? "error"
                : "info"
            }
            sx={{ width: "100%" }}
          >
            {info?.message || "!!!"}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}
