import { Add } from "@mui/icons-material";
import { Box, Button, List, TextField, Typography } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { load } from "react-cookies";
import LoadingView from "../../components/loading-view";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import Api from "../../utils/Api";
import { ResponseType } from "../../utils/ConstType";
import DialogAddClass from "../../components/dashboard/fg-class/dialog-add-class";
import DialogEditClass from "../../components/dashboard/fg-class/dialog-edit-class";
import ClassListItem from "../../components/dashboard/class-list-item";
import DialogDeleteClass from "../../components/dashboard/fg-class/dialog-delete-class";

export default function Classes() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [classs, setClasss] = useState<HbType.ClassDataType[]>([]);
  const [isAddClass, setIsAddClass] = useState(false);
  const [classInEditMode, setClassInEditMode] =
    useState<HbType.ClassDataType | null>(null);
  const [classInDeleteMode, setClassInDeleteMode] =
    useState<HbType.ClassDataType | null>(null);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  const filteredClasss = useMemo(() => {
    let sft = classs;
    if (searchQuery.length > 0) {
      const sq = (searchQuery || "").trim().toLowerCase();

      sft = sft.filter((o) => {
        if ((o.name || "").trim().toLowerCase().includes(sq)) {
          return true;
        }

        return false;
      });
    }

    return sft;
  }, [classs, searchQuery]);

  useEffect(() => {
    functionHandler.getAllClass();
  }, []);

  const functionHandler = {
    getAllClass() {
      setIsLoading(true);
      Api.handler.classes
        .all(loginToken)
        .then((response) => {
          // console.log("response is: ", response.data);
          const result = response.data as ResponseType;
          const tsrs = result.message as HbType.ClassDataType[];
          setClasss(tsrs);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  return (
    <Box>
      {isLoading && <LoadingView />}
      {isAddClass && (
        <DialogAddClass
          onClose={() => setIsAddClass(false)}
          onUpdate={() => {
            setIsAddClass(false);
            functionHandler.getAllClass();
          }}
        />
      )}
      {classInEditMode && (
        <DialogEditClass
          classs={classInEditMode}
          onClose={() => setClassInEditMode(null)}
          onUpdate={() => {
            setClassInEditMode(null);
            functionHandler.getAllClass();
          }}
        />
      )}
      {classInDeleteMode && (
        <DialogDeleteClass
          classs={classInDeleteMode}
          onClose={() => setClassInDeleteMode(null)}
          onDeleted={() => {
            setClassInDeleteMode(null);
            functionHandler.getAllClass();
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          gap: 0.5,
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          marginTop: 0.8,
          // height: 56,
          overflow: "hidden",
        }}
      >
        <Button
          sx={{ height: "100%" }}
          variant="contained"
          color="success"
          startIcon={<Add />}
          onClick={() => setIsAddClass(true)}
        >
          Add Class
        </Button>
        <TextField
          sx={{ flexGrow: 1 }}
          placeholder="Search..."
          variant="standard"
          type="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      <List>
        {/* class lists */}
        {filteredClasss.length > 0 &&
          filteredClasss.map((t) => (
            <ClassListItem
              class={t}
              onDelete={() => {setClassInDeleteMode(t)}}
              onEdit={() => setClassInEditMode(t)}
              key={t.id}
            />
          ))}
      </List>
    </Box>
  );
}
