/* eslint-disable jsx-a11y/img-redundant-alt */
import { AddAPhoto } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { load } from "react-cookies";
import Api from "../../../utils/Api";
import { HbType, ResponseType } from "../../../utils/ConstType";
import Constants from "../../../utils/Constants";
import Utils from "../../../utils/utils";
import LoadingView from "../../loading-view";
import { HBImageTint } from "../../temp-ef-image-root";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
import dayjs from "dayjs";

export default function DialogEditEvent({
  event,
  onClose,
  onUpdate,
}: {
  event: HbType.EventResponseType;
  onClose: () => void;
  onUpdate: () => void;
}) {
  const [info, _si] = useState<{
    status: boolean | null;
    message: string;
    visible: boolean;
  } | null>();

  const [firstLoad, setFirstLoad] = useState(true);
  const [inEvent, setInEvent] = useState<HbType.EventResponseType | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [grades, setGrades] = useState<HbType.GradeDataType[]>([]);
  const [teachers, setTeachers] = useState<HbType.TeacherDataType[]>([]);
  const [subjects, setSubjects] = useState<HbType.SubjectDataType[]>([]);
  const [classes, setClasses] = useState<HbType.ClassDataType[]>([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState<"once" | "daily" | "weekly" | "monthly">(
    "daily"
  );
  const [fullDayEvent, setFullDayEvent] = useState<"yes" | "no">("no");
  const [startHour, setStartHour] = useState(1);
  const [startMinute, setStartMinute] = useState(0);
  const [startAmPm, setStartAmPm] = useState<"AM" | "PM">("AM");
  const [endHour, setEndHour] = useState(1);
  const [endMinute, setEndMinute] = useState(0);
  const [endAmPm, setEndAmPm] = useState<"AM" | "PM">("AM");
  const [eventStartDate, setEventStartDate] = useState<any>();
  const [eventEndDate, setEventEndDate] = useState<any>();
  const [daysOfWeek, setDaysOfWeek] = useState<{
    sa: boolean;
    su: boolean;
    mo: boolean;
    tu: boolean;
    we: boolean;
    th: boolean;
    fr: boolean;
  }>({
    sa: true,
    su: true,
    mo: true,
    tu: true,
    we: true,
    th: true,
    fr: true,
  });
  const [daysOfMonth, setDaysOfMonth] = useState<number[]>([]);

  const [gradeId, setGradeId] = useState<number | null>(null);
  const [teacherId, setTeacherId] = useState<number | null>(null);
  const [subjectId, setSubjectId] = useState<number | null>(null);
  const [classId, setClassId] = useState<number | null>(null);

  const hours = useMemo(() => {
    let hr: number[] = [];

    for (var a = 1; a <= 12; a++) {
      hr.push(a);
    }

    return hr;
  }, []);
  const dates = useMemo(() => {
    let hr: number[] = [];

    for (var a = 1; a <= 31; a++) {
      hr.push(a);
    }

    return hr;
  }, []);
  const minutes = useMemo(() => {
    let v: number[] = [];

    for (var a = 0; a < 60; a++) {
      v.push(a);
    }

    return v;
  }, []);

  const inputFileRef = useRef(null);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  useEffect(() => {

    setInEvent(event);


    console.log("event is: ", event)


    // classId: classId,
    setClassId(event.class_id);
    // dayOfMonth: daysOfMonth,
    setDaysOfMonth(event.days_of_month);
    // dayOfWeek: daysOfWeek,
    setDaysOfWeek(event.days_of_week);
    // description: description,
    setDescription(event.description);
    // endTime: `${endHour}:${endMinute}:${endAmPm}`,
    const endTime = event.end_time.split(':');
    setEndHour(Number(endTime[0]));
    setEndMinute(Number(endTime[1]));
    setEndAmPm(endTime[2] as any);
    // eventType: type,
    setType(event.type);
    // fullDayEvent: fullDayEvent,
    setFullDayEvent(event.full_day_event);
    // gradeId: gradeId,
    setGradeId(event.grade_id);
    // startDate:
    //   esd.getFullYear() +
    //   "-" +
    //   (esd.getMonth() + 1) +
    //   "-" +
    //   esd.getDate(),
    setEventStartDate(dayjs(event.start_date));
    // endDate:
    //   eed.getFullYear() +
    //   "-" +
    //   (eed.getMonth() + 1) +
    //   "-" +
    //   eed.getDate(),
    setEventEndDate(dayjs(event.end_date));

    // startTime: `${startHour}:${startMinute}:${startAmPm}`,
    const startTime = event.start_time.split(':');
    setStartHour(Number(startTime[0]));
    setStartMinute(Number(startTime[1]));
    setStartAmPm(startTime[2] as any);
    // subjectId: subjectId,
    setSubjectId(event.subject_id);
    // teacherId: teacherId,
    setTeacherId(event.teacher_id);
    // title: title,
    setTitle(event.title);


    console.log("type after setting type: ", type, event.type);


    setFirstLoad(false);

    functionHandler.getClassSubjectTeacherGrade();
  }, []);

  useEffect(() => {
    if (startHour > endHour && startAmPm === endAmPm) {
      setEndHour(startHour);
    } else if (startHour === endHour && startAmPm === endAmPm) {
      if (startMinute > endMinute) {
        setEndMinute(startMinute);
      }
    }
  }, [startHour, startMinute, startAmPm, endHour, endMinute, endAmPm]);

  useEffect(() => {
    if (eventStartDate || eventEndDate) {
      if (!eventEndDate && eventStartDate) {
        setEventEndDate(eventStartDate);
      } else if (!eventStartDate && eventEndDate) {
        setEventStartDate(eventEndDate);
      }

      const sd = new Date(eventStartDate);
      const ed = new Date(eventEndDate);
      if (type === "once") {
        if (eventStartDate) {
          setEventEndDate(eventStartDate);
        } else setEventStartDate(eventEndDate);
      } else if (sd.getFullYear() >= ed.getFullYear()) {
        if (sd.getMonth() > ed.getMonth()) {
          console.log(
            "start month is greater than end month",
            sd.getMonth(),
            ed.getMonth()
          );
          setEventEndDate(eventStartDate);
        } else if (
          sd.getMonth() === ed.getMonth() &&
          sd.getDate() > ed.getDate()
        ) {
          console.log(
            "start date is greater than end date",
            sd.getDate(),
            ed.getDate()
          );
          setEventEndDate(eventStartDate);
        }
      }
    }
  }, [eventStartDate, eventEndDate]);

  useEffect(() => {

    if (firstLoad) {
      return;
    }

    if (type !== "daily" && type !== "weekly") {
      return;
    }

    console.log("daysofweek changed. validating", type, daysOfWeek);
    if (Object.values(daysOfWeek).every(Boolean)) {
      setType("daily");
    } else {
      setType("weekly");
      if (!JSON.stringify(daysOfWeek).includes("true")) {
        setDaysOfWeek({
          sa: true,
          su: false,
          mo: false,
          tu: false,
          we: false,
          th: false,
          fr: false,
        });
      }
    }
  }, [daysOfWeek]);

  useEffect(() => {


    if (firstLoad) {
      return;
    }

    // console.log("type changed. validating", type, daysOfWeek);
    if (type === "weekly" && Object.values(daysOfWeek).every(Boolean)) {
      setDaysOfWeek({
        sa: true,
        su: false,
        mo: false,
        tu: false,
        we: false,
        th: false,
        fr: false,
      });
    } else if (type === "daily") {
      setDaysOfWeek({
        sa: true,
        su: true,
        mo: true,
        tu: true,
        we: true,
        th: true,
        fr: true,
      });
    }
    if (type !== "monthly" && daysOfMonth.length > 0) {
      setDaysOfMonth([]);
    }

    if (type === "once") {
      if (eventStartDate) {
        setEventEndDate(eventStartDate);
      } else if (eventEndDate) {
        setEventStartDate(eventEndDate);
      }
    }
  }, [type]);

  const functionHandler = {
    handleSnackbarClose() {
      _si(null);
    },
    setInfo(status: boolean | null, message: string) {
      _si({
        visible: true,
        status: status,
        message: message,
      });
    },
    getClassSubjectTeacherGrade() {
      setIsLoading(true);
      Api.handler.function
        .getGradeTeacherSubjectandClass(loginToken)
        .then((res) => {
          // console.log("response of data is: ", res.data);
          const data: ResponseType = res.data as any;
          if (data.status === "success") {
            const dd = data.message as {
              teachers: HbType.TeacherDataType[];
              classes: HbType.ClassDataType[];
              subjects: HbType.SubjectDataType[];
              grades: HbType.GradeDataType[];
            };

            setTeachers(dd.teachers);
            setClasses(dd.classes);
            setSubjects(dd.subjects);
            setGrades(dd.grades);

            if (
              dd.teachers.length === 0 ||
              dd.classes.length === 0 ||
              dd.subjects.length === 0 ||
              dd.grades.length === 0
            ) {
              functionHandler.setInfo(
                false,
                "Teachers, Classes, Subjects and Grades are required. Please add them before adding event."
              );
              return;
            }

            // setTeacherId(dd.teachers[0].id);
            // setClassId(dd.classes[0].id);
            // setSubjectId(dd.subjects[0].id);
            // setGradeId(dd.grades[0].id);
          } else {
            functionHandler.setInfo(
              false,
              "Failed to fetch data from server. error: " + data.message
            );
          }
        })
        .catch((e) => {
          functionHandler.setInfo(
            false,
            "Failed to fetch data from server. error: " + e
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    saveEvent(shouldClose: boolean) {
      if (classId === null) {
        functionHandler.setInfo(false, "Please select class");
      } else if (!title) {
        functionHandler.setInfo(false, "Title is required");
      } else if (gradeId === null) {
        functionHandler.setInfo(false, "Please select grade");
      } else if (subjectId === null) {
        functionHandler.setInfo(false, "Please select subject");
      } else if (teacherId === null) {
        functionHandler.setInfo(false, "Please select teacher");
      } else if (type === "monthly" && daysOfMonth.length === 0) {
        functionHandler.setInfo(
          false,
          "Please select atleast one day of month"
        );
      } else if (!eventStartDate) {
        functionHandler.setInfo(false, "Please choose event start date");
      } else if (!eventEndDate) {
        functionHandler.setInfo(false, "Please choose event end date");
      } else {
        const esd = new Date(eventStartDate);
        const eed = new Date(eventEndDate);

        if (typeof inEvent?.id !== 'number') {
          functionHandler.setInfo(false, "Could not find ID for event. please try again later.");
          return;
        }

        const event: HbType.EventUpdateDataType = {
          id: inEvent?.id as number,
          classId: classId,
          dayOfMonth: daysOfMonth,
          dayOfWeek: daysOfWeek,
          description: description,
          endTime: `${endHour}:${endMinute}:${endAmPm}`,
          eventType: type,
          fullDayEvent: fullDayEvent,
          gradeId: gradeId,
          startDate:
            esd.getFullYear() +
            "-" +
            (esd.getMonth() + 1) +
            "-" +
            esd.getDate(),
          endDate:
            eed.getFullYear() +
            "-" +
            (eed.getMonth() + 1) +
            "-" +
            eed.getDate(),
          startTime: `${startHour}:${startMinute}:${startAmPm}`,
          subjectId: subjectId,
          teacherId: teacherId,
          title: title,
        };

        // setInEvent((o)=>({...o,...(event as any)}))



        Api.handler.events
          .update(event, loginToken)
          .then((res) => {
            console.log("response :", res.data);
            const response = res.data as ResponseType;

            if (response.status === "success") {
              functionHandler.setInfo(
                true,
                "Event has been added successfully."
              );

              onUpdate();

              // if (shouldClose) {
              //   onClose();
              // } else {
              //   setTitle("");
              //   setDescription("");
              // }
            } else {
              functionHandler.setInfo(false, "Error: " + response.message);
            }
          })
          .catch((e) =>
            functionHandler.setInfo(false, "Failed to save data. Error: " + e)
          );
      }
    },
  };

  // console.log("upload task: ", profileImageUploadableURl);
  // functionHandler.uploadProfile();

  console.log("type before return render is: ", type);

  return (
    <Dialog maxWidth="md" fullWidth open>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          margin={4}
          gap={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <HBImageTint
            sx={{ width: 55, height: 55 }}
            color={Constants.colors.lightTheme.palette.primary.main}
            src={Constants.icons.calendar}
          />
          <Typography
            variant="h6"
            color={Constants.colors.lightTheme.palette.primary.main}
          >
            Edit Event
          </Typography>
        </Box>

        <Box maxWidth={560} width="100%">
          <TextField
            label="Title"
            type="text"
            variant="standard"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            label="Description"
            type="text"
            multiline
            variant="standard"
            rows={4}
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Box display="flex" width="100%" mt={1}>
            <Box flexGrow={1} width="100%">
              <Typography fontWeight="bold">Grade :</Typography>
            </Box>
            <Box flexGrow={1} width="100%">
              <Select
                fullWidth
                variant="standard"
                value={gradeId}
                onChange={(e) => setGradeId(Number(e.target.value))}
              >
                {grades.map((g) => (
                  <MenuItem key={g.id} value={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box display="flex" width="100%" mt={1}>
            <Box flexGrow={1} width="100%">
              <Typography fontWeight="bold">Subject :</Typography>
            </Box>
            <Box flexGrow={1} width="100%">
              <Select
                fullWidth
                variant="standard"
                value={subjectId}
                onChange={(e) => setSubjectId(Number(e.target.value))}
              >
                {subjects.map((g) => (
                  <MenuItem key={g.id} value={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box display="flex" width="100%" mt={1}>
            <Box flexGrow={1} width="100%">
              <Typography fontWeight="bold">Class :</Typography>
            </Box>
            <Box flexGrow={1} width="100%">
              <Select
                fullWidth
                variant="standard"
                value={classId}
                onChange={(e) => setClassId(Number(e.target.value))}
              >
                {classes.map((g) => (
                  <MenuItem key={g.id} value={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box display="flex" width="100%" mt={1}>
            <Box flexGrow={1} width="100%">
              <Typography fontWeight="bold">Teacher :</Typography>
            </Box>
            <Box flexGrow={1} width="100%">
              <Select
                fullWidth
                variant="standard"
                value={teacherId}
                onChange={(e) => setTeacherId(e.target.value as any)}
              >
                {teachers.map((g) => (
                  <MenuItem key={g.id} value={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box display="flex" width="100%" mt={1}>
            <Box flexGrow={1} width="100%">
              <Typography fontWeight="bold">Select event type: </Typography>
            </Box>
            <Box flexGrow={1} width="100%">
              <Select
                fullWidth
                variant="standard"
                value={type}
                onChange={(e) => setType(e.target.value as any)}
              >
                <MenuItem value="once">Only Once</MenuItem>
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </Select>
            </Box>
          </Box>
          {(type === "weekly" || type === "daily") && (
            <Box display="flex" width="100%" mt={1} ml={2}>
              <Box flexGrow={1} width="100%">
                <Typography>Days: </Typography>
              </Box>
              <Box
                flexGrow={1}
                width="100%"
                flexWrap="wrap"
                display="flex"
                flexDirection="row"
                rowGap={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={daysOfWeek.sa}
                    onChange={(e) =>
                      setDaysOfWeek((o) => ({ ...o, sa: e.target.checked }))
                    }
                  />
                  <Typography fontSize={12}>SAT&nbsp;&nbsp;|</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={daysOfWeek.su}
                    onChange={(e) =>
                      setDaysOfWeek((o) => ({ ...o, su: e.target.checked }))
                    }
                  />
                  <Typography fontSize={12}>SUN&nbsp;&nbsp;|</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={daysOfWeek.mo}
                    onChange={(e) =>
                      setDaysOfWeek((o) => ({ ...o, mo: e.target.checked }))
                    }
                  />
                  <Typography fontSize={12}>MON&nbsp;&nbsp;|</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={daysOfWeek.tu}
                    onChange={(e) =>
                      setDaysOfWeek((o) => ({ ...o, tu: e.target.checked }))
                    }
                  />
                  <Typography fontSize={12}>TUE&nbsp;&nbsp;|</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={daysOfWeek.we}
                    onChange={(e) =>
                      setDaysOfWeek((o) => ({ ...o, we: e.target.checked }))
                    }
                  />
                  <Typography fontSize={12}>WED&nbsp;&nbsp;|</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={daysOfWeek.th}
                    onChange={(e) =>
                      setDaysOfWeek((o) => ({ ...o, th: e.target.checked }))
                    }
                  />
                  <Typography fontSize={12}>THU&nbsp;&nbsp;|</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={daysOfWeek.fr}
                    onChange={(e) =>
                      setDaysOfWeek((o) => ({ ...o, fr: e.target.checked }))
                    }
                  />
                  <Typography fontSize={12}>FRI</Typography>
                </Box>
              </Box>
            </Box>
          )}

          {type === "monthly" && (
            <Box display="flex" width="100%" mt={1} ml={2}>
              <Box flexGrow={1} width="100%">
                <Typography>Days: </Typography>
              </Box>
              <Box
                flexGrow={1}
                width="100%"
                flexWrap="wrap"
                display="flex"
                flexDirection="row"
                rowGap={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                {dates.map((d) => (
                  <Box key={d} display="flex" alignItems="center">
                    <Checkbox
                      checked={daysOfMonth.includes(d)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDaysOfMonth((o) =>
                            !o.includes(d) ? [...o, d] : o
                          );
                        } else {
                          setDaysOfMonth((o) => o.filter((x) => x !== d));
                        }
                      }}
                    />
                    <Typography fontSize={12}>
                      {String(d).length > 1 ? d : "0" + d}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          <Box
            display="flex"
            width="100%"
            mt={1}
            ml={2}
            pr={2}
            alignItems="center"
            justifyContent="center"
          >
            <Box flexGrow={1} width="100%">
              Start date:
            </Box>
            <Box flexGrow={1} width="100%">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start date"
                  value={eventStartDate}
                  onChange={(newValue) => setEventStartDate(newValue)}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Box
            display="flex"
            width="100%"
            mt={1}
            ml={2}
            pr={2}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Box flexGrow={1} width="100%">
              End date:
            </Box>
            <Box flexGrow={1} width="100%">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={type === "once"}
                  label="End date"
                  value={eventEndDate}
                  onChange={(newValue) => setEventEndDate(newValue)}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Box display="flex" width="100%" mt={1}>
            <Box flexGrow={1} width="100%">
              <Typography fontWeight="bold">Full day event:</Typography>
            </Box>
            <Box flexGrow={1} width="100%">
              <RadioGroup
                row
                value={fullDayEvent}
                onChange={(e) => setFullDayEvent(e.target.value as any)}
              >
                <FormControlLabel value="yes" label="yes" control={<Radio />} />
                <FormControlLabel value="no" label="no" control={<Radio />} />
              </RadioGroup>
            </Box>
          </Box>
          {fullDayEvent === "no" && (
            <Box>
              <Box display="flex" width="100%" mt={1} ml={2} pr={2}>
                <Box flexGrow={1} width="100%">
                  Start:
                </Box>
                <Box flexGrow={1} width="100%" display="flex">
                  <Select
                    disableUnderline
                    sx={{
                      border: "1px solid #ddd",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                    size="medium"
                    variant="standard"
                    value={startHour}
                    onChange={(e) => setStartHour(e.target.value as any)}
                  >
                    {hours.map((h) => (
                      <MenuItem key={h} value={h}>
                        <Typography sx={{ pl: 2, pr: 2 }}>
                          {String(h).length === 1 ? "0" + h : h}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    disableUnderline
                    sx={{
                      border: "1px solid #ddd",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                    size="medium"
                    variant="standard"
                    value={startMinute}
                    onChange={(e) => setStartMinute(e.target.value as any)}
                  >
                    {minutes.map((h) => (
                      <MenuItem key={h} value={h}>
                        <Typography sx={{ pl: 2, pr: 2 }}>
                          {String(h).length === 1 ? "0" + h : h}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    disableUnderline
                    sx={{
                      border: "1px solid #ddd",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                    size="medium"
                    variant="standard"
                    value={startAmPm}
                    onChange={(e) => setStartAmPm(e.target.value as any)}
                  >
                    <MenuItem value={"AM"}>
                      <Typography sx={{ pl: 2, pr: 2 }}>AM</Typography>
                    </MenuItem>
                    <MenuItem value={"PM"}>
                      <Typography sx={{ pl: 2, pr: 2 }}>PM</Typography>
                    </MenuItem>
                  </Select>
                </Box>
              </Box>

              <Box display="flex" width="100%" mt={1} ml={2} pr={2}>
                <Box flexGrow={1} width="100%">
                  End:
                </Box>

                <Box flexGrow={1} width="100%" display="flex">
                  <Select
                    disableUnderline
                    sx={{
                      border: "1px solid #ddd",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                    size="medium"
                    variant="standard"
                    value={endHour}
                    onChange={(e) => setEndHour(e.target.value as any)}
                  >
                    {hours.map((h) => (
                      <MenuItem key={h} value={h}>
                        <Typography sx={{ pl: 2, pr: 2 }}>
                          {String(h).length === 1 ? "0" + h : h}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    disableUnderline
                    sx={{
                      border: "1px solid #ddd",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                    size="medium"
                    variant="standard"
                    value={endMinute}
                    onChange={(e) => setEndMinute(e.target.value as any)}
                  >
                    {minutes.map((h) => (
                      <MenuItem key={h} value={h}>
                        <Typography sx={{ pl: 2, pr: 2 }}>
                          {String(h).length === 1 ? "0" + h : h}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    disableUnderline
                    sx={{
                      border: "1px solid #ddd",
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                    size="medium"
                    variant="standard"
                    value={endAmPm}
                    onChange={(e) => setEndAmPm(e.target.value as any)}
                  >
                    <MenuItem value={"AM"}>
                      <Typography sx={{ pl: 2, pr: 2 }}>AM</Typography>
                    </MenuItem>
                    <MenuItem value={"PM"}>
                      <Typography sx={{ pl: 2, pr: 2 }}>PM</Typography>
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          )}

          {/* kfsdjkfjksdlfjksdlfjksdlfjksdlfjksdljfklsdjf */}
        </Box>

        <Button
          sx={{ m: 0.4, mt: 2, maxWidth: 560 }}
          color="secondary"
          variant="contained"
          onClick={() => functionHandler.saveEvent(true)}
          fullWidth
        >
          Save
        </Button>
        <Button
          fullWidth
          sx={{ m: 0.4, maxWidth: 560 }}
          variant="contained"
          color="error"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Box sx={{ height: 100 }} />

        <Snackbar
          open={info?.visible}
          autoHideDuration={6000}
          onClose={functionHandler.handleSnackbarClose}
        >
          <Alert
            onClose={functionHandler.handleSnackbarClose}
            severity={
              info?.status === true
                ? "success"
                : info?.status === false
                  ? "error"
                  : "info"
            }
            sx={{ width: "100%" }}
          >
            {info?.message || "!!!"}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}
