import axios from "axios";
import { v4 } from "uuid";
import { HbType } from "./ConstType";

//TODO: always prepare api from Api.handler.{functionName}()
// const endPointUrl = "http://2.2.2.103:8033";

const endPointUrl = "https://napi.ism.ly";
// const endPointUrl = "https://lh.efortshub.com:4000";
// const endPointUrl = "http://10.2.2.3:8033";

const Api = {
  // endPointUrl: "https://ismschoool.com/efortshub",
  endpoints: {
    //get
    uploads: endPointUrl,
    //post
    login: (type: string) => endPointUrl + "/auth/" + type,
    //post formData
    uploadPhoto: (name: string, token: string) =>
      endPointUrl + "/upload/" + name + "/" + token,
    //get
    validate: (token: string) =>
      endPointUrl + "/auth/validate/campusAdmin/" + token,
    //get
    allTeachers: (token: string) =>
      endPointUrl + "/campusAdmin/all-teacher/" + token,
    //post
    uploadTeacher: endPointUrl + "/campusAdmin/upload-teachers",
    //post
    addTeacher: endPointUrl + "/campusAdmin/add-teacher",
    //post
    updateTeacher:  endPointUrl + "/campusAdmin/update-teacher",
    //post
    deleteTeacher: endPointUrl + "/campusAdmin/delete-teacher",
    //post
    deleteEvent: endPointUrl + "/campusAdmin/delete-event",
    //get
    allStudent: (token: string) =>
      endPointUrl + "/campusAdmin/all-students/" + token,
    //post
    uploadStudent: endPointUrl + "/campusAdmin/upload-students",
    //post
    addStudent: endPointUrl + "/campusAdmin/add-student",
    //post
    updateStudent: endPointUrl + "/campusAdmin/update-student",
    //post
    deleteStudent: endPointUrl + "/campusAdmin/delete-student",
    //get
    allGrades: (token: string) =>
      endPointUrl + "/campusAdmin/all-grades/" + token,
    //post
    addGrade: endPointUrl + "/campusAdmin/add-grade",
    //post
    updateGrade: endPointUrl + "/campusAdmin/update-grade",
    //post
    deleteGrade: endPointUrl + "/campusAdmin/delete-grade",
    //get
    allClasses: (token: string) =>
      endPointUrl + "/campusAdmin/all-classes/" + token,
    //post
    addClass: endPointUrl + "/campusAdmin/add-class",
    //post
    updateClass: endPointUrl + "/campusAdmin/update-class",
    //post
    deleteClass: endPointUrl + "/campusAdmin/delete-class",
    //get
    allSubjects: (token: string) =>
      endPointUrl + "/campusAdmin/all-subjects/" + token,
    //post
    addSubject: endPointUrl + "/campusAdmin/add-subject",
    //post
    updateSubject: endPointUrl + "/campusAdmin/update-subject",
    //post
    deleteSubject: endPointUrl + "/campusAdmin/delete-subject",
    //get
    allEvent: (token: string) =>
      endPointUrl + "/campusAdmin/all-events/" + token,
    //post
    addEvent: endPointUrl + "/campusAdmin/add-event",
    //post
    updateEvent: endPointUrl +"/campusAdmin/update-event",
    //get
    getGradeTeacherSubjectandClass: (token: string) =>
      endPointUrl + "/campusAdmin/gtsc/" + token,
    //get
    setSupervisor: (id: number, token: string) =>
      endPointUrl + "/campusAdmin/set-sup/" + id + "/" + token,
    //get
    getSupervisor: (token: string) =>
      endPointUrl + "/campusAdmin/get-sup/" + token,
  },
  handler: {
    function: {
      getGradeTeacherSubjectandClass(token: string) {
        return axios.get(Api.endpoints.getGradeTeacherSubjectandClass(token));
      },
    },
    uploadPhoto: (data: FormData, name: string, token: string) => {
      return axios.post(Api.endpoints.uploadPhoto(name, token), data);
    },
    supervisor: {
      get(token: string) {
        return axios.get(Api.endpoints.getSupervisor(token));
      },
      set(id: number, token: string) {
        return axios.get(Api.endpoints.setSupervisor(id, token));
      },
    },
    events: {
      all(token: string) {
        return axios.get(Api.endpoints.allEvent(token));
      },
      add(event: HbType.EventDataType, token: string) {
        console.log(
          "my start and end time is: ",
          event.startDate,
          event.endDate
        );
        return axios.post(Api.endpoints.addEvent, { ...event, token: token });
      },
      delete(eventId:number, token:string){
        return axios.post(Api.endpoints.deleteEvent, {
          event_id: eventId,
          token: token,
        });

      },
      update(event: HbType.EventDataType, token:string){
        return axios.post(Api.endpoints.updateEvent, { ...event, token: token });
      }
    },
    grades: {
      all(token: string) {
        return axios.get(Api.endpoints.allGrades(token));
      },
      add(name: string, token: string) {
        return axios.post(Api.endpoints.addGrade, {
          name: name,
          token: token,
        });
      },
      update(id:number,name:string,token:string){
        return axios.post(Api.endpoints.updateGrade, {
          id:id,
          name: name,
          token: token,
        });
      },
      delete(id:number,token:string){
        return axios.post(Api.endpoints.deleteGrade, {
          id:id,
          token: token,
        });
      }
    },
    subjects: {
      all(token: string) {
        return axios.get(Api.endpoints.allSubjects(token));
      },
      add(name: string, token: string) {
        return axios.post(Api.endpoints.addSubject, {
          name: name,
          token: token,
        });
      },
      update(id:number,name:string,token:string){
        return axios.post(Api.endpoints.updateSubject, {
          id:id,
          name: name,
          token: token,
        });
      },
      delete(id:number,token:string){
        return axios.post(Api.endpoints.deleteSubject, {
          id:id,
          token: token,
        });
      }
    },
    classes: {
      all(token: string) {
        return axios.get(Api.endpoints.allClasses(token));
      },
      add(name: string, token: string) {
        return axios.post(Api.endpoints.addClass, {
          name: name,
          token: token,
        });
      },
      update(id:number,name:string,token:string){
        return axios.post(Api.endpoints.updateClass, {
          id:id,
          name: name,
          token: token,
        });
      },
      delete(id:number,token:string){
        return axios.post(Api.endpoints.deleteClass, {
          id:id,
          token: token,
        });
      }
    },
    students: {
      all(token: string) {
        return axios.get(Api.endpoints.allStudent(token));
      },
      delete(id:number, token:string){

        return axios.post(Api.endpoints.deleteStudent, {
          token: token,
          id:id,
        });
      },
      upload(
        campusId: number,
        token: string,
        userData: {
          student_id_num: number;
          grade: string;
          section: string;
          first_name: string;
          middle_name: string;
          last_name: string;
          email: string;
          nationality: string;
          notice: string;
          phone: string;
          password: string;
        }[]
      ) {
        return axios.post(Api.endpoints.uploadStudent, {
          token: token,
          campus_id: campusId,
          data: userData,
        });
      },
      add(
        studentId:number,
        firstName: string,
        middleName: string,
        lastName: string,
        email: string,
        phone: string,
        grade: number,
        parentsName: string,
        parentsEmail: string,
        parentsPhone: string,
        password: string,
        token: string
      ) {
        const pp = v4().split("-")[0];
        return axios.post(Api.endpoints.addStudent, {
          student_id_num:studentId,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          email: email,
          phone: phone,
          grade: grade,
          parents_name: parentsName,
          parents_email: parentsEmail,
          parents_phone: parentsPhone,
          password: password,
          token: token,
          parents_password: pp,
        });
      },
      update(
        id:number,
        studentId:number,
        firstName: string,
        middleName: string,
        lastName: string,
        email: string,
        phone: string,
        grade: number,
        parentsName: string,
        parentsEmail: string,
        parentsPhone: string,
        parentsPassword:string,
        nationality:string,
        notice:string,
        password: string,
        token: string
      ) {
        return axios.post(Api.endpoints.updateStudent, {
          id:id,
          student_id_num:studentId,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          email: email,
          phone: phone,
          grade: grade,
          parents_name: parentsName,
          parents_email: parentsEmail,
          parents_phone: parentsPhone,
          parents_password:parentsPassword,
          nationality:nationality,
          notice:notice,
          password: password,
          token: token
        });
      },
    },
    teachers: {
      all(token: string) {
        return axios.get(Api.endpoints.allTeachers(token));
      },

      upload(
        campusId: number,
        token: string,
        userData: {
          teacher_id_num: number;
          first_name: string;
          middle_name: string;
          last_name: string;
          email: string;
          nationality: string;
          notice: string;
          phone: string;
          password: string;
        }[]
      ) {
        return axios.post(Api.endpoints.uploadTeacher, {
          token: token,
          campus_id: campusId,
          data: userData,
        });
      },
      update(
        id:number,
        name: string,
        email: string,
        phone: string,
        password: string,
        token: string
      ) {
        return axios.post(Api.endpoints.updateTeacher, {
          id:id,
          name: name,
          email: email,
          phone: phone,
          password: password,
          token: token,
        });
      },
      add(
        name: string,
        email: string,
        phone: string,
        password: string,
        token: string
      ) {
        return axios.post(Api.endpoints.addTeacher, {
          name: name,
          email: email,
          phone: phone,
          password: password,
          token: token,
        });
      },
      delete(
        teacherId:number,
        token: string
      ) {
        return axios.post(Api.endpoints.deleteTeacher, {
          teacher_id: teacherId,
          token: token,
        });
      },
    },
    login(email: string, password: string, token: string, type: string) {
      return axios.post(Api.endpoints.login(type), {
        email: email,
        password: password,
        token: token,
      });
    },
    validate(loginToken: string) {
      return axios.get(Api.endpoints.validate(loginToken));
    },
  },
};

export default Api;
