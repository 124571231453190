import { Forward } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import Utils from "../../utils/utils";
import { HBImageTint } from "../temp-ef-image-root";

export default function SupervisorListItem({
  teacher: t,
  onSelect,
  isSupervisor,
}: {
  teacher: HbType.TeacherDataType;
  onSelect: () => void;
  isSupervisor: boolean;
}) {
  return (
    <ListItem disablePadding>
      <Paper
        sx={{
          margin: 0.3,
          width: "100%",
          minWidth: 200,
          // border: "1px solid black",
          padding: "4px 8px",
          borderRadius: 2,
          backgroundColor: isSupervisor
            ? Constants.colors.lightTheme.palette.warning.main + "20"
            : Constants.colors.lightTheme.palette.background.paper,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          "&:hover": {
            // cursor:'pointer',
            backgroundColor: "#00000005",
          },
        }}
      >
        <Box>
          <Avatar
            sx={{
              backgroundColor:
                Constants.colors.lightTheme.palette.secondary.main,
              m: 2,
            }}
          >
            {t?.profile_url ? (
              <img
                style={{ width: "100%", height: "100%" }}
                src={Utils.retrieveImageURL(t?.profile_url)}
                alt="profile iamge"
              />
            ) : (
              <HBImageTint
                color={Constants.colors.lightTheme.palette.text?.onPrimary}
                sx={{
                  width: "100%",
                  height: "100%",
                }}
                src={Constants.images.teacher}
                alt="profile image"
              />
            )}

            {/* <FallbackImageView
              src={Utils.retrieveImageURL(t?.profile_url)}
              fallback={Constants.images.teacher}
            /> */}
          </Avatar>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            <Typography
              variant="h6"
              color={Constants.colors.lightTheme.palette.text?.primary}
            >
              {t?.name}
            </Typography>
            {/* <Typography
              variant="subtitle2"
              color={Constants.colors.lightTheme.palette.secondary.main}
            >{`Admin: ${c.ca_name}`}</Typography> */}
            <Typography
              color={Constants.colors.lightTheme.palette.text?.secondary}
              sx={{ maxLines: 2 }}
            >
              {t?.email}
            </Typography>
          </Box>
          <Box>
            {!isSupervisor && (
              <IconButton
                onClick={onSelect}
                size="medium"
                sx={{
                  // borderRadius: 2,
                  m: 0.3,
                  backgroundColor:
                    Constants.colors.lightTheme.palette.primary.main,
                  color: "white",
                  "&:hover": {
                    color: Constants.colors.lightTheme.palette.primary.main,
                  },
                }}
              >
                <Forward />
              </IconButton>
            )}
          </Box>
        </Box>
      </Paper>
    </ListItem>
  );
}
