import { Box, List, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { load } from "react-cookies";
import DialogChooseSuepervisor from "../../components/dashboard/fg-supervisor/dialog-choose-supervisor";
import DialogAddTeacher from "../../components/dashboard/fg-teacher/dialog-add-teacher";
import SupervisorListItem from "../../components/dashboard/supervisor-list-item";
import LoadingView from "../../components/loading-view";
import Api from "../../utils/Api";
import { HbType, ResponseType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";

export default function Supervisor() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [teachers, setTeachers] = useState<HbType.TeacherDataType[]>([]);
  const [isAddTeacher, setIsAddTeacher] = useState(false);
  const [teacherInEditMode, setTeacherInEditMode] =
    useState<HbType.TeacherDataType | null>(null);
  const [currentSupervisor, setCurrentSupervisor] = useState(-99);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  const filteredTeachers = useMemo(() => {
    let sft = teachers.filter((t) => t.id !== currentSupervisor);

    if (searchQuery.length > 0) {
      const sq = (searchQuery || "").trim().toLowerCase();

      sft = sft.filter((o) => {
        if (
          (o.name || "").trim().toLowerCase().includes(sq) ||
          (o.email || "").trim().toLowerCase().includes(sq) ||
          (o.phone || "").trim().toLowerCase().includes(sq)
        ) {
          return true;
        }

        return false;
      });
    }

    return sft;
  }, [teachers, searchQuery, currentSupervisor]);

  useEffect(() => {
    functionHandler.getAllTeacher();
    functionHandler.getSupervisor();
  }, []);

  const functionHandler = {
    getSupervisor() {
      Api.handler.supervisor.get(loginToken).then((res) => {
        const data = res.data as ResponseType;
        if (data.status === "success") {
          setCurrentSupervisor(Number(String(data.message)));
        }
      });
    },
    getAllTeacher() {
      setIsLoading(true);
      Api.handler.teachers
        .all(loginToken)
        .then((response) => {
          // console.log("response is: ", response.data);
          const result = response.data as ResponseType;
          const tsrs = result.message as HbType.TeacherDataType[];
          setTeachers(tsrs);
        })
        .catch((err) => { })
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  return (
    <Box>
      {isLoading && <LoadingView />}
      {isAddTeacher && (
        <DialogAddTeacher
          onClose={() => setIsAddTeacher(false)}
          onUpdate={() => {
            setIsAddTeacher(false);
            functionHandler.getAllTeacher();
          }}
        />
      )}
      {teacherInEditMode && (
        <DialogChooseSuepervisor
          teacher={teacherInEditMode}
          onClose={() => setTeacherInEditMode(null)}
          onUpdate={() => {
            setTeacherInEditMode((o) => {
              setCurrentSupervisor(o?.id || -1);
              return null;
            });
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          gap: 0.5,
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          marginTop: 0.8,
          // height: 56,
          overflow: "hidden",
        }}
      >
        <TextField
          sx={{ flexGrow: 1 }}
          placeholder="Search..."
          variant="standard"
          type="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      {currentSupervisor >= 0 && (
        <Box
          sx={{
            mt: 2,
            mb: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6">Currently selected supervisor</Typography>
          <SupervisorListItem
            isSupervisor
            onSelect={() => { }}
            teacher={teachers.filter((t) => t.id === currentSupervisor)[0]}
          />
        </Box>
      )}

      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">Choose another teacher</Typography>
      </Box>
      <List>
        {/* teacher lists */}
        {filteredTeachers.length > 0 &&

          filteredTeachers.map &&
          
          filteredTeachers.map((t) => (
            <SupervisorListItem
              teacher={t}
              isSupervisor={t.id === currentSupervisor}
              onSelect={() => {
                setTeacherInEditMode(t);
              }}
              key={t.id}
            />
          ))}
      </List>
    </Box>
  );
}
