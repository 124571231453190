import { Add } from "@mui/icons-material";
import { Box, Button, List, TextField, Typography } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { load } from "react-cookies";
import LoadingView from "../../components/loading-view";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import Api from "../../utils/Api";
import { ResponseType } from "../../utils/ConstType";
import DialogAddSubject from "../../components/dashboard/fg-subject/dialog-add-subject";
import DialogEditSubject from "../../components/dashboard/fg-subject/dialog-edit-subject";
import SubjectListItem from "../../components/dashboard/subject-list-item";
import DialogDeleteSubject from "../../components/dashboard/fg-subject/dialog-delete-subject";

export default function Subjects() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [subjects, setSubjects] = useState<HbType.SubjectDataType[]>([]);
  const [isAddSubject, setIsAddSubject] = useState(false);
  const [subjectInEditMode, setSubjectInEditMode] =
    useState<HbType.SubjectDataType | null>(null);
  const [subjectInDeleteMode, setSubjectInDeleteMode] =
    useState<HbType.SubjectDataType | null>(null);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  const filteredSubjects = useMemo(() => {
    let sft = subjects;
    if (searchQuery.length > 0) {
      const sq = (searchQuery || "").trim().toLowerCase();

      sft = sft.filter((o) => {
        if ((o.name || "").trim().toLowerCase().includes(sq)) {
          return true;
        }

        return false;
      });
    }

    return sft;
  }, [subjects, searchQuery]);

  useEffect(() => {
    functionHandler.getAllSubject();
  }, []);

  const functionHandler = {
    getAllSubject() {
      setIsLoading(true);
      Api.handler.subjects
        .all(loginToken)
        .then((response) => {
          // console.log("response is: ", response.data);
          const result = response.data as ResponseType;
          const tsrs = result.message as HbType.SubjectDataType[];
          setSubjects(tsrs);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  return (
    <Box>
      {isLoading && <LoadingView />}
      {isAddSubject && (
        <DialogAddSubject
          onClose={() => setIsAddSubject(false)}
          onUpdate={() => {
            setIsAddSubject(false);
            functionHandler.getAllSubject();
          }}
        />
      )}
      {subjectInEditMode && (
        <DialogEditSubject
          subject={subjectInEditMode}
          onClose={() => setSubjectInEditMode(null)}
          onUpdate={() => {
            setSubjectInEditMode(null);
            functionHandler.getAllSubject();
          }}
        />
      )}

      {subjectInDeleteMode && (
        <DialogDeleteSubject
          subject={subjectInDeleteMode}
          onClose={() => setSubjectInDeleteMode(null)}
          onDeleted={() => {
            setSubjectInDeleteMode(null);
            functionHandler.getAllSubject();
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          gap: 0.5,
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          marginTop: 0.8,
          // height: 56,
          overflow: "hidden",
        }}
      >
        <Button
          sx={{ height: "100%" }}
          variant="contained"
          color="success"
          startIcon={<Add />}
          onClick={() => setIsAddSubject(true)}
        >
          Add Subject
        </Button>
        <TextField
          sx={{ flexGrow: 1 }}
          placeholder="Search..."
          variant="standard"
          type="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      <List>
        {/* subject lists */}
        {filteredSubjects.length > 0 &&
          filteredSubjects.map((t) => (
            <SubjectListItem
              subject={t}
              onDelete={() => {
                setSubjectInDeleteMode(t);
              }}
              onEdit={() => setSubjectInEditMode(t)}
              key={t.id}
            />
          ))}
      </List>
    </Box>
  );
}
