import { DeleteForever, Edit } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";

export default function EventListItem({
  event,
  onDelete,
  onEdit,
}: {
  event: HbType.EventResponseType;
  onDelete: () => void;
  onEdit: () => void;
}) {
  event.days_of_week = Object.fromEntries(
    Object.entries(event.days_of_week).sort((a, b) => {
      const order = ["su", "mo", "tu", "we", "th", "fr", "sa"];
      return order.indexOf(a[0]) - order.indexOf(b[0]);
    })
  ) as any;
  const dbSpl = event.start_time.split(":");
  const dbEpl = event.end_time.split(":");

  event.start_time =
    dbSpl[0] +
    ":" +
    (dbSpl[1].length === 1 ? "0" + dbSpl[1] : dbSpl[1]) +
    ":" +
    dbSpl[2];

  event.end_time =
    dbEpl[0] +
    ":" +
    (dbEpl[1].length === 1 ? "0" + dbEpl[1] : dbEpl[1]) +
    ":" +
    dbEpl[2];

  // console.log("event date is: ", event.start_date, event.end_date)

  return (
    <ListItem disablePadding>
      <Paper
        sx={{
          margin: 0.3,
          width: "100%",
          minWidth: 200,
          // border: "1px solid black",
          padding: "4px 8px",
          borderRadius: 2,
          backgroundColor: Constants.colors.lightTheme.palette.background.paper,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          "&:hover": {
            // cursor:'pointer',
            backgroundColor: "#00000005",
          },
        }}
      >
        <Box
          width="100%"
          display="flex"
          gap={0}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="100%">
            {/* event details area  */}
            <Box
              sx={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              {event.type === "once" ? (
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  mr={1}
                  justifyContent={"center"}
                >
                  <Typography noWrap fontSize={10}>
                    at
                  </Typography>
                  <Typography
                    noWrap
                    textTransform="uppercase"
                    bgcolor={Constants.colors.lightTheme.palette.warning.main}
                    color={Constants.colors.lightTheme.palette.text.primary}
                    p="2px 6px"
                    borderRadius={4}
                    fontSize={10}
                    boxShadow="1px 2px 5px 2px #ddd"
                  >
                    {event.start_date}
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  mr={1}
                >
                  <Typography noWrap fontSize={10}>
                    from
                  </Typography>
                  <Typography
                    noWrap
                    textTransform="uppercase"
                    bgcolor={Constants.colors.lightTheme.palette.warning.main}
                    color={Constants.colors.lightTheme.palette.text.primary}
                    p="2px 6px"
                    borderRadius={4}
                    fontSize={10}
                    boxShadow="1px 2px 5px 2px #ddd"
                  >
                    {event.start_date}
                  </Typography>
                  <Typography noWrap fontSize={10}>
                    to
                  </Typography>
                  <Typography
                    noWrap
                    textTransform="uppercase"
                    bgcolor={Constants.colors.lightTheme.palette.warning.main}
                    color={Constants.colors.lightTheme.palette.text.primary}
                    p="2px 6px"
                    borderRadius={4}
                    fontSize={10}
                    boxShadow="1px 2px 5px 2px #ddd"
                  >
                    {event.end_date}
                  </Typography>
                </Box>
              )}
              <Box
                display="flex"
                gap={0}
                alignItems="flex-start"
                flexDirection="column"
                flexGrow={1}
              >
                <Typography
                  noWrap
                  fontSize={12}
                  variant="subtitle1"
                  color={Constants.colors.lightTheme.palette.text.primary}
                >
                  {event.subjectName}
                </Typography>
                <Typography
                  noWrap
                  fontSize={14}
                  variant="subtitle2"
                  color={Constants.colors.lightTheme.palette.primary.main}
                  fontWeight="bold"
                >
                  {event.title}
                </Typography>
                <Typography
                  noWrap
                  fontSize={12}
                  variant="subtitle1"
                  color={Constants.colors.lightTheme.palette.text.primary}
                >
                  {event.teacherName}
                </Typography>
              </Box>
              <Box
                height="100%"
                ml={1}
                mr={1}
                minHeight="100%"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Typography
                  color={Constants.colors.lightTheme.palette.text.primary}
                  fontSize={10}
                  sx={{
                    maxLines: 4,
                    lineClamp: 4,
                    WebkitLineClamp: 4,
                    overflow: "hidden",
                    maxHeight: 72,
                  }}
                >
                  {event.description}
                </Typography>
              </Box>
            </Box>

            {/* //event type area  */}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              flexWrap="wrap"
              // mt="4px"
            >
              <Typography
                noWrap
                textTransform="uppercase"
                bgcolor={
                  Constants.colors.lightTheme.palette.secondary.main + "f0"
                }
                color={Constants.colors.lightTheme.palette.text.onPrimary}
                p="2px 6px"
                mt={1}
                mb={1}
                borderRadius={4}
                fontSize={10}
                boxShadow="1px 2px 5px 2px #ddd"
              >
                <b
                  style={{
                    color: Constants.colors.lightTheme.palette.text.onSecondary,
                  }}
                >
                  {event.type}
                </b>{" "}
                {event.start_time} - {event.end_time}
              </Typography>
              {event.type === "monthly" ? (
                event.days_of_month.map((day) => (
                  <Box
                    key={day}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Checkbox size="small" checked />
                    <Typography noWrap fontSize={12} textTransform="uppercase">
                      {day}
                    </Typography>
                  </Box>
                ))
              ) : event.type === "weekly" ? (
                Object.keys(event.days_of_week).map((day) => (
                  <Box
                    key={day}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Checkbox
                      size="small"
                      checked={(event as any).days_of_week[day] === true}
                    />
                    <Typography noWrap fontSize={12} textTransform="uppercase">
                      {day}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Box />
              )}
            </Box>
          </Box>

          <Box display={"flex"} flexDirection={"column"}>
            <IconButton
              onClick={onEdit}
              size="medium"
              sx={{
                // borderRadius: 2,
                m: 0.3,
                backgroundColor:
                  Constants.colors.lightTheme.palette.primary.main,
                color: "white",
                "&:hover": {
                  color: Constants.colors.lightTheme.palette.primary.main,
                },
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={onDelete}
              size="medium"
              sx={{
                // borderRadius: 2,
                m: 0.3,
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  color: "red",
                },
              }}
            >
              <DeleteForever />
            </IconButton>
          </Box>
        </Box>
      </Paper>
    </ListItem>
  );
}
