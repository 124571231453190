/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  List,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useMemo, useEffect, useRef } from "react";
import { load } from "react-cookies";
import LoadingView from "../../components/loading-view";
import { HbType } from "../../utils/ConstType";
import Constants from "../../utils/Constants";
import Api from "../../utils/Api";
import { ResponseType } from "../../utils/ConstType";
import AddEvent from "../../components/dashboard/fg-calendar/fg-add-event";
import EventListItem from "../../components/dashboard/event-list-item";
import DialogDeleteEvent from "../../components/dashboard/fg-calendar/dialog-delete-event";
import DialogEditEvent from "../../components/dashboard/fg-calendar/dialog-edit-event";

export default function Calendars() {
  const [info, _si] = useState<{
    status: boolean | null;
    message: string;
    visible: boolean;
  } | null>();

  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAddEvent, setIsAddEvent] = useState(false);
  const [events, setEvents] = useState<HbType.EventResponseType[]>([]);

  const [eventInEditMode, setEventInEditMode] =
    useState<HbType.EventResponseType | null>(null);
  const [eventInDeleteMode, setEventInDeleteMode] =
    useState<HbType.EventResponseType | null>(null);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  const filteredEvents = useMemo(() => {
    let sft = events;
    if (searchQuery.length > 0) {
      const sq = (searchQuery || "").trim().toLowerCase();

      sft = sft.filter((o) => {
        if (
          (o.title || "").trim().toLowerCase().includes(sq) ||
          (o.description || "").trim().toLowerCase().includes(sq) ||
          (o.className || "").trim().toLowerCase().startsWith(sq) ||
          (o.teacherName || "").trim().toLowerCase().startsWith(sq) ||
          (o.gradeName || "").trim().toLowerCase().startsWith(sq) ||
          (o.subjectName || "").trim().toLowerCase().startsWith(sq)
        ) {
          return true;
        }

        return false;
      });
    }

    return sft;
  }, [events, searchQuery]);

  // pagination

  const [paginLimit, setPaginLimit] = useState(10);
  const [paginCurrentPage, setPaginCurrentPage] = useState(1);
  const paginationRootRef = useRef(null);
  const paginationFooterRef = useRef(null);

  const paginPages = useMemo(() => {
    return Math.ceil(filteredEvents.length / paginLimit);
  }, [paginLimit, filteredEvents]);

  const footerContent = useMemo(() => {
    const buttons = [];

    for (var i = paginCurrentPage - 2; i < paginCurrentPage + 3; i++) {
      if (i === paginCurrentPage) {
        buttons.push(
          <Button variant="contained" color="info" disabled>
            {i}
          </Button>
        );
      } else if (i > 0 && i <= paginPages) {
        const x = i;
        buttons.push(
          <Button
            variant="contained"
            color="warning"
            onClick={() => setPaginCurrentPage(x)}
          >
            {i}
          </Button>
        );
      }
    }

    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          fontSize: "22px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setPaginCurrentPage(1)}
        >
          {"< First"}
        </Button>

        {buttons}

        <Button
          variant="contained"
          color="primary"
          onClick={() => setPaginCurrentPage(paginPages)}
        >
          {"Last >"}
        </Button>
      </Box>
    );
  }, [paginCurrentPage, paginPages]);

  //pagination
  

  // useEffect(() => {
  //   functionHandler.getAllEvents();
  // }, []);
  useEffect(() => {
    if (!isAddEvent) {
      functionHandler.getAllEvents();
    }
  }, [isAddEvent]);

  const functionHandler = {
    handleSnackbarClose() {
      _si(null);
    },
    setInfo(status: boolean | null, message: string) {
      _si({
        visible: true,
        status: status,
        message: message,
      });
    },
    getAllEvents() {
      setIsLoading(true);
      Api.handler.events
        .all(loginToken)
        .then((res) => {
          const response = res.data as ResponseType;
          if (response.status === "success") {
            console.log("got response : ", response);

            //TODO: change when testing done

            // setEvents(response.message.slice(response.message.length - 10));

            setEvents(response.message);
          } else {
            functionHandler.setInfo(
              false,
              "Something wen't wrong. " + response.message
            );
          }
        })
        .catch((e) => {
          functionHandler.setInfo(
            false,
            "Failed to load data. Please try again later"
          );
        })
        .finally(() => setIsLoading(false));
    },
  };

  if (isAddEvent) {
    return <AddEvent onClose={() => setIsAddEvent(false)} />;
  }

  console.log(
    "currentPage: ",
    paginCurrentPage,
    "paginLImit: ",
    paginLimit,
    "event length: ",
    events.length,
    "filtered length: ",
    filteredEvents.length
  );

  return (
    <Box>
      {isLoading && <LoadingView />}

      {/* {eventInEditMode && (
        <Dia
          teacher={teacherInEditMode}
          onClose={() => setTeacherInEditMode(null)}
          onUpdate={() => {
            setTeachers((o) =>
              o.map((x) =>
                x.id === teacherInEditMode.id ? teacherInEditMode : x
              )
            );
            setTeacherInEditMode(null);
          }}
        />
      )} */}

      {eventInEditMode && (
        <DialogEditEvent
          event={eventInEditMode}
          onClose={() => setEventInEditMode(null)}
          onUpdate={() => {
            setEventInEditMode(null);
            functionHandler.getAllEvents();
          }}
        />
      )}

      {eventInDeleteMode && (
        <DialogDeleteEvent
          event={eventInDeleteMode}
          onClose={() => setEventInDeleteMode(null)}
          onDeleted={() => {
            setEvents((o) => o.filter((x) => x.id !== eventInDeleteMode.id));
            setEventInDeleteMode(null);
          }}
        />
      )}

      <Box
        sx={{
          display: "flex",
          gap: 0.5,
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          marginTop: 0.8,
          // height: 56,
          overflow: "hidden",
        }}
      >
        <Button
          sx={{ height: "100%" }}
          variant="contained"
          color="success"
          startIcon={<Add />}
          onClick={() => setIsAddEvent(true)}
        >
          Add Event
        </Button>
        <TextField
          sx={{ flexGrow: 1 }}
          placeholder="Search..."
          variant="standard"
          type="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      <Box
        ref={paginationRootRef}
        pt={1}
        sx={{
          height: `calc(100vh - ${
            (paginationRootRef.current || ({ offsetTop: 0 } as any)).offsetTop +
            64
          }px)`,
        }}
      >
        {/* {filteredEvents.map((e) => (
          <EventListItem key={e.id} event={e} onDelete={() => setEventInDeleteMode(e)} onEdit={() => setEventInEditMode(e)} />
        ))} */}

        <Box
          sx={{
            height: "100%",
            border: 3,
            borderRadius: 2,
            borderColor:
              Constants.colors.lightTheme.palette.text.secondary + "35",
            p: 1,
            m: 0,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",

            // display: flex;
            // justify-content: center;
            // align-items: center;
            // flex-direction: column;
          }}
        >
          <Box
            sx={{
              // height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              p: 1,
            }}
          >
            <Select
              autoWidth
              variant="outlined"
              size="small"
              value={paginLimit}
              onChange={(e) => setPaginLimit(Number(e.target.value))}
            >
              {[10, 25, 50, 100, 250].map((g) => (
                <MenuItem key={g} value={g}>
                  {g}
                </MenuItem>
              ))}
            </Select>
            <Typography> &nbsp;Record per page.</Typography>
          </Box>

          <Box
            sx={{
              // flex:1,
              // alignSelf: "stretch",
              boxSizing: "border-box",
              MozBoxSizing: "border-box",
              WebkitBoxSizing: "border-box",
              height: "100%",
              width: "100%",
              overflow: "auto",
              // backgroundColor: "red",

              // overflowY: "hidden",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                // backgroundColor: "yellow",
              }}
            >
              {filteredEvents.length > 0 &&
                Array.apply(null, { length: paginLimit } as any)
                  .map(Number.call, Number)
                  .map(
                    (n: any) =>
                      filteredEvents.length >
                        n + paginLimit * paginCurrentPage - paginLimit && (
                        <EventListItem
                          key={n}
                          event={
                            filteredEvents[
                              n + paginLimit * paginCurrentPage - paginLimit
                            ]
                          }
                          onDelete={() =>
                            setEventInDeleteMode(
                              filteredEvents[
                                n + paginLimit * paginCurrentPage - paginLimit
                              ]
                            )
                          }
                          onEdit={() =>
                            setEventInEditMode(
                              filteredEvents[
                                n + paginLimit * paginCurrentPage - paginLimit
                              ]
                            )
                          }
                        />
                      )
                  )}
            </Box>
          </Box>

          <Box
            ref={paginationFooterRef}
            sx={{
              width: "100%",
              padding: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              // backgroundColor: "blue",
            }}
          >
            {footerContent}
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={info?.visible}
        autoHideDuration={6000}
        onClose={functionHandler.handleSnackbarClose}
      >
        <Alert
          onClose={functionHandler.handleSnackbarClose}
          severity={
            info?.status === true
              ? "success"
              : info?.status === false
              ? "error"
              : "info"
          }
          sx={{ width: "100%" }}
        >
          {info?.message || "!!!"}
        </Alert>
      </Snackbar>
    </Box>
  );
}
