/* eslint-disable jsx-a11y/img-redundant-alt */
import { AddAPhoto } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { load } from "react-cookies";
import Api from "../../../utils/Api";
import { HbType, ResponseType } from "../../../utils/ConstType";
import Constants from "../../../utils/Constants";
import Utils from "../../../utils/utils";
import LoadingView from "../../loading-view";
import { HBImageTint } from "../../temp-ef-image-root";
import { MD5 } from "crypto-js";

export default function DialogEditStudent({
  student,
  onClose,
  onUpdate,
}: {
  student: HbType.StudentDataType;
  onClose: () => void;
  onUpdate: () => void;
}) {
  const [info, _si] = useState<{
    status: boolean | null;
    message: string;
    visible: boolean;
  } | null>();

  const [studentIdNum, setStudentIdNum] = useState(-1);
  const [studentIdError, setstudentIdError] = useState(false);
  const [itemAdded, setItemAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [grade, setGrade] = useState<number | null>(null);
  const [parentsPhone, setParentsPhone] = useState("");
  const [parentsName, setParentsName] = useState("");
  const [parentsEmail, setParentsEmail] = useState("");
  const [parentsPassword, setParentsPassword] = useState("");
  const [nationality, setNationality] = useState("");
  const [notice, setNotice] = useState("");
  const [parentsPasswordError, setParentsPasswordError] = useState(false);
  const [caPassword, setCaPassword] = useState("");
  const [caVerifyPassword, setCaVerifyPassword] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setphoneErrorr] = useState(false);
  const [caPasswordError, setCaPasswordError] = useState(false);
  const [caVerifyPasswordError, setCaVerifyPasswordError] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [profileImageUploadableURl, setProfileImageUploadableUrl] =
    useState<File | null>(null);

  const [grades, setGrades] = useState<HbType.GradeDataType[]>([]);

  const inputFileRef = useRef(null);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  useEffect(() => {
    setStudentIdNum(student.student_id_num);
    setFirstName(student.first_name);
    setMiddleName(student.middle_name);
    setLastName(student.last_name);
    setPhone(student.phone);
    setEmail(student.email);
    setGrade(student.grade);
    setParentsName(student.parents_name);
    setParentsEmail(student.parents_email);
    setParentsPhone(student.parents_phone);
    setParentsPassword(student.parents_password);
    setNationality(student.nationality);
    setNotice(student.notice);
    setProfileImageUrl(Utils.retrieveImageURL(student.profile_url));
    functionHandler.getGrades();
  }, []);

  useEffect(() => {
    if (profileImageUploadableURl) {
      functionHandler.uploadProfile();
    }
  }, [profileImageUploadableURl]);

  const functionHandler = {
    handleSnackbarClose() {
      _si(null);
    },
    setInfo(status: boolean | null, message: string) {
      _si({
        visible: true,
        status: status,
        message: message,
      });
    },
    handleEditStudent() {
      setNameError(false);
      setEmailError(false);
      setphoneErrorr(false);
      setCaPasswordError(false);
      setParentsPasswordError(false);
      setCaVerifyPasswordError(false);
      if (!studentIdNum) {
        // alert("Campus name is required");
        setstudentIdError(true);
        return;
      }
      if (!firstName || firstName.length === 0) {
        // alert("Campus name is required");
        setNameError(true);
        return;
      }
      if (!grade) {
        alert("Please choose a grade first.");
        return;
      }
      const emailTester = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!email || !emailTester.test(String(email).toLowerCase())) {
        setEmailError(true);
        return;
      }
      if (!phone || phone.length <= 7) {
        setphoneErrorr(true);
        return;
      }

      if (!parentsPassword) {
        setParentsPasswordError(false);
      }

      // if (!caPassword || caPassword.length <= 7) {
      //   setCaPasswordError(true);
      //   alert("password length must be greater than 8");
      //   return;
      // }
      if (caVerifyPassword !== caPassword) {
        setCaVerifyPasswordError(true);
        return;
      }
      functionHandler.setInfo(null, "Updating student details. Please wait...");
      // setIsLoading(true);

      Api.handler.students
        .update(
          student.id,
          studentIdNum,
          firstName,
          middleName,
          lastName,
          email,
          phone,
          grade,
          parentsName,
          parentsEmail,
          parentsPhone,
          parentsPassword,
          nationality,
          notice,
          MD5(caPassword).toString(),
          loginToken
        )
        .then((response) => {
          const apiResponse: ResponseType = response.data;
          if (apiResponse.status === "success") {
            onUpdate();
          } else {
            functionHandler.setInfo(false, apiResponse.message);
          }
        })
        .catch((e) => {
          console.log("error on creating student", e);
          functionHandler.setInfo(
            false,
            "Failed to create Student. error: " + e
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    // handleAddStudent(shouldHide: boolean) {
    //   setNameError(false);
    //   setEmailError(false);
    //   setphoneErrorr(false);
    //   setCaPasswordError(false);
    //   setCaVerifyPasswordError(false);
    //   if (!name || name.length === 0) {
    //     // alert("Campus name is required");
    //     setNameError(true);
    //     return;
    //   }
    //   const emailTester = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    //   if (!email || !emailTester.test(String(email).toLowerCase())) {
    //     setEmailError(true);
    //     return;
    //   }
    //   if (!caPassword || caPassword.length <= 7) {
    //     setCaPasswordError(true);
    //     alert("password length must be greater than 8");
    //     return;
    //   }
    //   if (!caVerifyPassword || caVerifyPassword !== caPassword) {
    //     setCaVerifyPasswordError(true);
    //     return;
    //   }
    //   functionHandler.setInfo(null, "Uploading data. Please wait...");
    //   setIsLoading(true);

    //   Api.handler.students
    //     .add(name, email,phone, caPassword, loginToken)
    //     .then((response) => {
    //       const apiResponse: ResponseType = response.data;
    //       if (apiResponse.status === "success") {
    //         if (shouldHide) {
    //           onUpdate();
    //         } else {
    //           setItemAdded(true);
    //           functionHandler.setInfo(
    //             true,
    //             "Student has been added successfully"
    //           );

    //           setName("");
    //           setEmail("");
    //           setCaPassword("");
    //           setCaVerifyPassword("");
    //         }
    //       } else {
    //         functionHandler.setInfo(false, apiResponse.message);
    //       }
    //     })
    //     .catch((e) => {
    //       console.log("error on creating campus", e);
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //     });
    // },

    onImagePicked(event: React.ChangeEvent<HTMLInputElement>) {
      const files = event.target.files;
      if (files && files?.length > 0) {
        const purl = files[0];
        setProfileImageUploadableUrl(purl);

        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
          console.log("reader result is: ", reader.result);
          setProfileImageUrl(reader.result as any);
        });
        reader.readAsDataURL(purl);
      }
    },
    uploadProfile() {
      try {
        if (profileImageUploadableURl !== null) {
          // const spl = profileImageUploadableURl?.name.split(".");
          // const a = spl ? spl[(spl?.length || 0) - 1] : "png";

          setIsLoading(true);

          const name = Utils.getStudentProfileImage(
            student.campus_id,
            student.id
          );
          const formData = new FormData();
          formData.append("photo", profileImageUploadableURl);

          Api.handler
            .uploadPhoto(formData, name, loginToken)
            .then((response) => {
              functionHandler.setInfo(true, "Profile image uploaded");

              console.log("file uploaded. response: ", response.data);
            })
            .catch((error) => {
              setProfileImageUrl(Utils.retrieveImageURL(student.profile_url));
              setProfileImageUploadableUrl(null);

              functionHandler.setInfo(
                false,
                "failed to upload photo. reason: " + error
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } catch (e) {}
    },

    getGrades() {
      setIsLoading(true);
      Api.handler.grades
        .all(loginToken)
        .then((res) => {
          const resdata = res.data as ResponseType;
          const grds = (resdata.message as HbType.GradeDataType[]) || [];

          if (grds.length === 0) {
            grds.push({ name: "Select Grade", id: 0 });
          }

          setGrades(grds);
        })
        .catch((e) => {
          functionHandler.setInfo(false, "Error getting Grades, error: " + e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  console.log("upload task: ", profileImageUploadableURl);
  // functionHandler.uploadProfile();

  return (
    <Dialog maxWidth="sm" fullWidth open>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading && <LoadingView />}
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.2,
            width: "100%",
            minWidth: 200,
            // backgroundColor:'red',
            padding: 4,
            top: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              color={Constants.colors.lightTheme.palette.primary.main}
            >
              Edit Student
            </Typography>
            <Avatar sx={{ width: 100, height: 100, overflow: "visible" }}>
              <Avatar sx={{ width: "100%", height: "100%" }}>
                {profileImageUrl ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={profileImageUrl}
                    alt="profile iamge"
                  />
                ) : (
                  <HBImageTint
                    color={Constants.colors.lightTheme.palette.text.onPrimary}
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={Constants.images.student}
                    alt="profile image"
                  />
                )}
              </Avatar>
              <IconButton
                size="large"
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  width: "100%",
                  height: "100%",
                  border: "1px solid white",
                  backgroundColor: "transparent",
                  color: "transparent",
                  "&:hover": {
                    backgroundColor:
                      Constants.colors.lightTheme.palette.primary.main + "d0",
                    color: Constants.colors.lightTheme.palette.text.onPrimary,
                  },
                }}
                onClick={() => (inputFileRef?.current as any).click()}
              >
                <AddAPhoto sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Avatar>
          </Box>

          <input
            ref={inputFileRef}
            name="upp"
            type="file"
            accept="image/*"
            title="Choose profile image"
            onChange={functionHandler.onImagePicked}
            style={{ display: "none" }}
          />

          <TextField
            variant="standard"
            label="Student ID"
            disabled
            value={studentIdNum}
            error={studentIdError}
            type="number"
            onChange={(event) => {
              setStudentIdNum(Number(event.target.value));
            }}
          />
          <TextField
            variant="standard"
            label="First Name"
            value={firstName}
            error={nameError}
            type="text"
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            label="Middle Name"
            value={middleName}
            type="text"
            onChange={(event) => {
              setMiddleName(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            label="Last Name"
            value={lastName}
            type="text"
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />

          <TextField
            variant="standard"
            required
            label="Email"
            value={email}
            type="email"
            error={emailError}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            required
            label="Phone"
            value={phone}
            type="tel"
            error={phoneError}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />

          {grades.length > 0 && (
            <Box width="100%">
              <InputLabel id="grade-label">Choose grade*</InputLabel>
              <Select
                fullWidth
                value={grade}
                // label="Grade"
                color="primary"
                onChange={(e) => {
                  setGrade(Number(e.target.value));
                }}
                title="Grade"
                labelId="grade-label"
              >
                {grades.map((g) => (
                  <MenuItem key={g.id} value={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          <TextField
            variant="standard"
            label="Parent's Name"
            value={parentsName}
            type="text"
            onChange={(event) => {
              setParentsName(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            label="Parent's Email"
            value={parentsEmail}
            type="email"
            onChange={(event) => {
              setParentsEmail(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            label="Parent's Phone"
            value={parentsPhone}
            type="tel"
            onChange={(event) => {
              setParentsPhone(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            label="Parent's Password"
            value={parentsPassword}
            error={parentsPasswordError}
            type="text"
            onChange={(event) => {
              setParentsPassword(event.target.value);
            }}
          />

          <TextField
            variant="standard"
            label="Nationality"
            value={nationality}
            type="text"
            onChange={(event) => {
              setNationality(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            label="Notice"
            value={notice}
            type="text"
            onChange={(event) => {
              setNotice(event.target.value);
            }}
          />
          <Typography
            variant="subtitle2"
            mt={1}
            sx={{
              color: Constants.colors.lightTheme.palette.secondary.main,
            }}
          >
            Change old password (optional)
          </Typography>
          <TextField
            variant="standard"
            type="password"
            label="New password"
            value={caPassword}
            error={caPasswordError}
            onChange={(event) => {
              setCaPassword(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            type="password"
            label="Verify password"
            value={caVerifyPassword}
            error={caVerifyPasswordError}
            onChange={(event) => {
              setCaVerifyPassword(event.target.value);
            }}
          />
          <Button
            sx={{ mt: 4 }}
            variant="contained"
            onClick={functionHandler.handleEditStudent}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={itemAdded ? onUpdate : onClose}
          >
            Cancel
          </Button>
        </Paper>

        <Snackbar
          open={info?.visible}
          autoHideDuration={6000}
          onClose={functionHandler.handleSnackbarClose}
        >
          <Alert
            onClose={functionHandler.handleSnackbarClose}
            severity={
              info?.status === true
                ? "success"
                : info?.status === false
                ? "error"
                : "info"
            }
            sx={{ width: "100%" }}
          >
            {info?.message || "!!!"}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}
