import { Person } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { MD5 } from "crypto-js";
import { useEffect, useMemo, useState } from "react";
import { load } from "react-cookies";
import Api from "../../../utils/Api";
import { HbType, ResponseType } from "../../../utils/ConstType";
import Constants from "../../../utils/Constants";
import LoadingView from "../../loading-view";

export default function DialogAddStudent({
  onClose,
  onUpdate,
}: {
  onClose: () => void;
  onUpdate: () => void;
}) {
  const [info, _si] = useState<{
    status: boolean | null;
    message: string;
    visible: boolean;
  } | null>();

  const [itemAdded, setItemAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [studentId, setStudentId] = useState<number | null>(null);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [grade, setGrade] = useState<number | null>(null);
  const [parentsPhone, setParentsPhone] = useState("");
  const [parentsName, setParentsName] = useState("");
  const [parentsEmail, setParentsEmail] = useState("");
  const [caPassword, setCaPassword] = useState("");
  const [caVerifyPassword, setCaVerifyPassword] = useState("");
  const [studentIdError, setstudentIdError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [caPasswordError, setCaPasswordError] = useState(false);
  const [caVerifyPasswordError, setCaVerifyPasswordError] = useState(false);
  const [grades, setGrades] = useState<HbType.GradeDataType[]>([]);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  useEffect(() => {
    functionHandler.getGrades();
  }, []);

  const functionHandler = {
    handleSnackbarClose() {
      _si(null);
    },
    setInfo(status: boolean | null, message: string) {
      _si({
        visible: true,
        status: status,
        message: message,
      });
    },
    handleAddStudent(shouldHide: boolean) {
      setNameError(false);
      setEmailError(false);
      setPhoneError(false);
      setCaPasswordError(false);
      setCaVerifyPasswordError(false);
      if (!studentId) {
        // alert("Campus name is required");
        setstudentIdError(true);
        return;
      }
      if (!firstName || firstName.length === 0) {
        // alert("Campus name is required");
        setNameError(true);
        return;
      }
      if (!grade) {
        alert("Please choose a grade first.");
        return;
      }
      const emailTester = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (!email || !emailTester.test(String(email).toLowerCase())) {
        setEmailError(true);
        return;
      }
      if (!phone || phone.length <= 7) {
        setPhoneError(true);
        return;
      }
      // if (!caPassword || caPassword.length <= 7) {
      //   setCaPasswordError(true);
      //   alert("password length must be greater than 8");
      //   return;
      // }
      if (!caVerifyPassword || caVerifyPassword !== caPassword) {
        setCaVerifyPasswordError(true);
        return;
      }
      functionHandler.setInfo(null, "Adding student details. Please wait...");
      setIsLoading(true);

      Api.handler.students
        .add(
          studentId,
          firstName,
          middleName,
          lastName,
          email,
          phone,
          grade,
          parentsName,
          parentsEmail,
          parentsPhone,
          MD5(caPassword).toString(),
          loginToken
        )
        .then((response) => {
          const apiResponse: ResponseType = response.data;
          if (apiResponse.status === "success") {
            if (shouldHide) {
              onUpdate();
            } else {
              setItemAdded(true);
              functionHandler.setInfo(
                true,
                "Student has been added successfully"
              );
              setStudentId(null);
              setFirstName("");
              setMiddleName("");
              setLastName("");
              setEmail("");
              setPhone("");
              setGrade(null);
              setParentsName("");
              setParentsEmail("");
              setParentsPhone("");
              setCaPassword("");
              setCaVerifyPassword("");
            }
          } else {
            functionHandler.setInfo(false, apiResponse.message);
          }
        })
        .catch((e) => {
          console.log("error on creating student", e);
          functionHandler.setInfo(
            false,
            "Failed to create Student. error: " + e
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    getGrades() {
      setIsLoading(true);
      Api.handler.grades
        .all(loginToken)
        .then((res) => {
          const resdata = res.data as ResponseType;
          const grds = (resdata.message as HbType.GradeDataType[]) || [];

          if (grds.length === 0) {
            grds.push({ name: "Select Grade", id: 0 });
          }

          // debugger;

          setGrades(grds);
          setGrade(grds[0]?.id);
        })
        .catch((e) => {
          functionHandler.setInfo(false, "Error getting Grades, error: " + e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  };

  return (
    <Dialog maxWidth="sm" fullWidth open>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading && <LoadingView />}
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.2,
            width: "100%",
            minWidth: 200,
            // backgroundColor:'red',
            padding: 4,
            top: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Person sx={{ height: 32, width: 32 }} />
            <Typography
              variant="subtitle2"
              color={Constants.colors.lightTheme.palette.primary.main}
            >
              Add student :
            </Typography>
          </Box>
          <TextField
            variant="standard"
            label="Student ID"
            value={studentId}
            error={studentIdError}
            type="number"
            onChange={(event) => {
              setStudentId(Number(event.target.value) || null);
            }}
          />
          <TextField
            variant="standard"
            label="First Name"
            value={firstName}
            error={nameError}
            type="text"
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            label="Middle Name"
            value={middleName}
            type="text"
            onChange={(event) => {
              setMiddleName(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            label="Last Name"
            value={lastName}
            type="text"
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            required
            label="Email"
            value={email}
            type="email"
            error={emailError}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            required
            label="Phone"
            value={phone}
            type="tel"
            error={phoneError}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />

          {grades.length > 0 && (
            <Box width="100%">
              <InputLabel id="grade-label">Choose grade*</InputLabel>
              <Select
                fullWidth
                value={grade}
                // label="Grade"
                color="primary"
                onChange={(e) => {
                  setGrade(Number(e.target.value));
                }}
                title="Grade"
                labelId="grade-label"
              >
                {grades.map((g) => (
                  <MenuItem key={g.id} value={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          <TextField
            variant="standard"
            label="Parent's Name"
            value={parentsName}
            type="text"
            onChange={(event) => {
              setParentsName(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            label="Parent's Email"
            value={parentsEmail}
            type="email"
            onChange={(event) => {
              setParentsEmail(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            label="Parent's Phone"
            value={parentsPhone}
            type="tel"
            onChange={(event) => {
              setParentsPhone(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            type="password"
            required
            label="Password"
            value={caPassword}
            error={caPasswordError}
            onChange={(event) => {
              setCaPassword(event.target.value);
            }}
          />
          <TextField
            variant="standard"
            type="password"
            required
            label="Verify password"
            value={caVerifyPassword}
            error={caVerifyPasswordError}
            onChange={(event) => {
              setCaVerifyPassword(event.target.value);
            }}
          />
          <Button
            sx={{ mt: 4 }}
            variant="contained"
            onClick={() => {
              functionHandler.handleAddStudent(true);
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              functionHandler.handleAddStudent(false);
            }}
          >
            Save and add next
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={itemAdded ? onUpdate : onClose}
          >
            Cancel
          </Button>
        </Paper>

        <Snackbar
          open={info?.visible}
          autoHideDuration={6000}
          onClose={functionHandler.handleSnackbarClose}
        >
          <Alert
            onClose={functionHandler.handleSnackbarClose}
            severity={
              info?.status === true
                ? "success"
                : info?.status === false
                  ? "error"
                  : "info"
            }
            sx={{ width: "100%" }}
          >
            {info?.message || "!!!"}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}
