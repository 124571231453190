/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  Alert,
  Box,
  Button,
  Dialog,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { load } from "react-cookies";
import { HbType, ResponseType } from "../../../utils/ConstType";
import Constants from "../../../utils/Constants";
import LoadingView from "../../loading-view";
import Api from "../../../utils/Api";

export default function DialogEditGrade({
  grade,
  onClose,
  onUpdate,
}: {
  grade: HbType.GradeDataType;
  onClose: () => void;
  onUpdate: () => void;
}) {
  const [info, _si] = useState<{
    status: boolean | null;
    message: string;
    visible: boolean;
  } | null>();

  const [itemAdded, setItemAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  
  const [nameError, setNameError] = useState(false);

  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );

  useEffect(() => {
    setName(grade.name);
  }, []);

  const functionHandler = {
    handleSnackbarClose() {
      _si(null);
    },
    setInfo(status: boolean | null, message: string) {
      _si({
        visible: true,
        status: status,
        message: message,
      });
    },
    handleEditGrade(){
      setIsLoading(true);


      Api.handler.grades
      .update(grade.id, name, loginToken)
      .then((res) => {
        console.log("response :", res.data);
        const response = res.data as ResponseType;

        if (response.status === "success") {
          functionHandler.setInfo(
            true,
            "Event has been updated successfully."
          );

          onUpdate();
    }
  }).finally(()=>setIsLoading(false));
}
    // handleAddGrade(shouldHide: boolean) {
    //   setNameError(false);
    //   setEmailError(false);
    //   setPhoneError(false);
    //   setCaPasswordError(false);
    //   setCaVerifyPasswordError(false);
    //   if (!name || name.length === 0) {
    //     // alert("Campus name is required");
    //     setNameError(true);
    //     return;
    //   }
    //   const emailTester = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    //   if (!email || !emailTester.test(String(email).toLowerCase())) {
    //     setEmailError(true);
    //     return;
    //   }
    //   if (!caPassword || caPassword.length <= 7) {
    //     setCaPasswordError(true);
    //     alert("password length must be greater than 8");
    //     return;
    //   }
    //   if (!caVerifyPassword || caVerifyPassword !== caPassword) {
    //     setCaVerifyPasswordError(true);
    //     return;
    //   }
    //   functionHandler.setInfo(null, "Uploading data. Please wait...");
    //   setIsLoading(true);

    //   Api.handler.grades
    //     .add(name, email,phone, caPassword, loginToken)
    //     .then((response) => {
    //       const apiResponse: ResponseType = response.data;
    //       if (apiResponse.status === "success") {
    //         if (shouldHide) {
    //           onUpdate();
    //         } else {
    //           setItemAdded(true);
    //           functionHandler.setInfo(
    //             true,
    //             "Grade has been added successfully"
    //           );

    //           setName("");
    //           setEmail("");
    //           setCaPassword("");
    //           setCaVerifyPassword("");
    //         }
    //       } else {
    //         functionHandler.setInfo(false, apiResponse.message);
    //       }
    //     })
    //     .catch((e) => {
    //       console.log("error on creating campus", e);
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //     });
    // },
    // onImagePicked(event: React.ChangeEvent<HTMLInputElement>) {
    //   const files = event.target.files;
    //   if (files && files?.length > 0) {
    //     const purl = files[0];
    //     setProfileImageUploadableUrl(purl);
    //     const reader = new FileReader();
    //     reader.addEventListener("load", (event) => {
    //       console.log("reader result is: ", reader.result);
    //       setProfileImageUrl(reader.result as any);
    //     });
    //     reader.readAsDataURL(purl);
    //   }
    // },
    // uploadProfile() {
    //   try {
    //     const spl = profileImageUploadableURl?.name.split(".");
    //     const a = spl ? spl[(spl?.length || 0) - 1] : "png";
    //     const fileName = grade.campus_id + "_" + grade.id + "." + a;
    //     console.log("upload file: ", fileName);
    //   } catch (e) {}
    // },
  };

  return (
    <Dialog maxWidth="sm" fullWidth  open>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading && <LoadingView />}
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.2,
            width: "100%",
            minWidth: 200,
            // backgroundColor:'red',
            padding: 4,
            top: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              color={Constants.colors.lightTheme.palette.primary.main}
            >
              Edit Grade
            </Typography>
          </Box>

          <TextField
            variant="standard"
            label="Name"
            value={name}
            error={nameError}
            type="text"
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <Button
            sx={{ mt: 4 }}
            variant="contained"
            onClick={() => {
              functionHandler.handleEditGrade();
              //edit grade
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={itemAdded ? onUpdate : onClose}
          >
            Cancel
          </Button>
        </Paper>

        <Snackbar
          open={info?.visible}
          autoHideDuration={6000}
          onClose={functionHandler.handleSnackbarClose}
        >
          <Alert
            onClose={functionHandler.handleSnackbarClose}
            severity={
              info?.status === true
                ? "success"
                : info?.status === false
                ? "error"
                : "info"
            }
            sx={{ width: "100%" }}
          >
            {info?.message || "!!!"}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}
