import { Person } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { MD5 } from "crypto-js";
import { useEffect, useMemo, useRef, useState } from "react";
import { load } from "react-cookies";
import Api from "../../../utils/Api";
import { HbType, ResponseType } from "../../../utils/ConstType";
import Constants from "../../../utils/Constants";
import EfStyleSheet from "../../../utils/style-helper";
import LoadingView from "../../loading-view";
import { HBImageTint } from "../../temp-ef-image-root";

export default function DialogUploadTeacher({
  onClose,
  onUpdate,
}: {
  onClose: () => void;
  onUpdate: () => void;
}) {
  const [info, _si] = useState<{
    status: boolean | null;
    message: string;
    visible: boolean;
  } | null>();

  const [isLoading, setIsLoading] = useState(false);

  const [userData, setUserData] = useState<any[]>([]);

  const fileInput = useRef<any>(null);


  const loginToken = useMemo(
    () => load(Constants.key.CookiesKey.loginToken),
    []
  );
  const campusInfo: HbType.CampusDataType = useMemo(
    () => load(Constants.key.CookiesKey.campusInfo),
    []
  );

  useEffect(() => {
  }, []);

  const functionHandler = {

    handleSnackbarClose() {
      _si(null);
    },


    setInfo(status: boolean | null, message: string) {
      _si({
        visible: true,
        status: status,
        message: message,
      });
    },


    handleUploadTeacher() {
      functionHandler.setInfo(null, "Adding teacher details. Please wait...");
      setIsLoading(true);

      Api.handler.teachers
        .upload(campusInfo.id, loginToken,
          userData.map(o => ({
            ...o, password:
              MD5(o.password).toString()
          })))
        .then((response) => {
          const apiResponse: ResponseType = response.data;
          if (apiResponse.status === "success") {
            onUpdate();

          } else {
            functionHandler.setInfo(false, apiResponse.message);
          }
        })
        .catch((e) => {
          console.log("error on creating teacher", e);
          functionHandler.setInfo(
            false,
            "Failed to create Teacher. error: " + e
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    onFilePicked(e: any) {
      e.preventDefault();
      // root.classList.remove('dragging');
      // rootStyle.backgroundColor = "#ffffff00";
      // rootStyle.border = "0px dashed #ddd";


      const target = e.dataTransfer || e.target;


      const files = target.files;
      const file = files[0];

      if (!file) return;

      if (file.type === 'text/csv') {

        // root.innerHTML =
        //   `
        //       <span> file has been added</span>
        //       `;

        console.log("file found: ", file)

        const reader = new FileReader();

        reader.addEventListener('load', (ev) => {

          if (!ev.target) return;

          const csvText = ev.target.result as string;

          if (!csvText) return;

          const raw = csvText.split("\n");
          const data = (raw.map((r) => {
            const rawsplit = r.split(",");
            var teacher_id_num = (rawsplit[0] || "").replaceAll("\r", "").replaceAll("\"", "");
            var password = (rawsplit[1] || "").replaceAll("\r", "").replaceAll("\"", "");
            var first_name = (rawsplit[2] || "").replaceAll("\r", "").replaceAll("\"", "");
            var middle_name = (rawsplit[3] || "").replaceAll("\r", "").replaceAll("\"", "");
            var last_name = (rawsplit[4] || "").replaceAll("\r", "").replaceAll("\"", "");
            var email = (rawsplit[5] || "").replaceAll("\r", "").replaceAll("\"", "");
            var nationality = (rawsplit[6] || "").replaceAll("\r", "").replaceAll("\"", "");
            var notice = (rawsplit[7] || "").replaceAll("\r", "").replaceAll("\"", "");
            var phone = (rawsplit[8] || "").replaceAll("\r", "").replaceAll("\"", "");

            return {
              teacher_id_num,
              first_name, middle_name,
              last_name, email, nationality,
              notice, phone, password
            }

          })).filter((o) => {

            let valid = !! Number(o.teacher_id_num);
            // debugger;

            return valid;

            // if (valid) return out;
          });

          console.log("data is: ", data);

          setUserData((o) => [...o, ...data]);

          if (data.length <= 0) {
            alert("You have inserted an Empty .csv file. Please drop another one.")
          }

        });

        reader.readAsText(file);

        // Papa.parse(file, {
        //   complete: function (results) {
        //     console.log(results.data); // Do something with the CSV data
        //   }
        // });
      } else {
        alert('Please drop a CSV file');
      }

    }

  };



  const style = EfStyleSheet.create(
    {
      templateStyle: {
        border: '1px dashed #bdbdbd',
        borderRadius: '8px',
        flex: 1,
        padding: 2,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 180



      }
    }

  );


  return (
    <Dialog maxWidth="lg" fullWidth open>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input  style={{ display: 'none', visibility: 'hidden' }} type="file" ref={fileInput} onChange={functionHandler.onFilePicked} />
        {isLoading && <LoadingView />}
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.2,
            // maxWidth: "100%",
            minWidth: 200,
            width: '100%',
            // backgroundColor:'red',
            padding: 4,
            top: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Person sx={{ height: 32, width: 32 }} />
            <Typography
              variant="subtitle2"
              color={Constants.colors.lightTheme.palette.primary.main}
            >
              Upload Teacher :
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>
              To <Typography sx={{ color: '#0088d6', display: 'inline' }}>'add multiple records'</Typography> download, fill and upload the 'template' file.
            </Typography>

            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              gap: 1,
              flexDirection: 'row',
              width: '100%'
            }}>

              {/* <Box sx={style.templateStyle}>
                <HBImageTint sx={{ width: 38, padding: 4 }} ignoreTint={true} color="black" src={Constants.icons.downloadFile} />
                <Typography textAlign="center">
                  <Typography sx={{ color: '#0088d6', display: 'inline' }}>Download</Typography> the template
                </Typography>

              </Box> */}

              <Box sx={{
                ...style.templateStyle,
                '&:hover': {
                  bgcolor: '#deeefe',
                  cursor: 'pointer'

                }
              }}
                onClick={() => { fileInput?.current && fileInput?.current.click(); }}
              >
                <HBImageTint sx={{ width: 38, padding: 4 }} ignoreTint={true} color="black" src={Constants.icons.uploadFile} />
                <Typography textAlign="center">
                {/* Drop you files here or */}
                   Click here to <Typography sx={{ color: '#0088d6', display: 'inline' }}>upload</Typography>
                </Typography>

                <Typography textAlign="center">
                  Upload .csv format files here
                </Typography>
              </Box>
            </Box>


            {/* <Box flexGrow={1} sx={{ mt: 2, mb: 2, width: '100%' }}>
              <Select
                variant="standard"
                value={campusId}
                onChange={(e) => setCampusId(o => Number(e.target.value) || o)}
              >
                {campuses.map(c => <MenuItem value={c.id}>{c.name}</MenuItem>)}
              </Select>
            </Box> */}

            {userData &&
              <TableContainer component={Paper} sx={{ width: '100%', height: '30vh', overflow: 'scroll' }}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Teacher ID</TableCell>
                      <TableCell align="right">Campus&nbsp;&nbsp;</TableCell>
                      <TableCell align="right">First Name</TableCell>
                      <TableCell align="right">Middle Name</TableCell>
                      <TableCell align="right">Last Name</TableCell>
                      <TableCell align="right">Email</TableCell>
                      <TableCell align="right">Password</TableCell>
                      <TableCell align="right">Nationality</TableCell>
                      <TableCell align="right">Notice</TableCell>
                      <TableCell align="right">Phone</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData.map((row) => (
                      <TableRow
                        key={row.teacher_id_num}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.teacher_id_num}
                        </TableCell>
                        <TableCell align="right">{campusInfo.name}</TableCell>
                        <TableCell align="right">{row.first_name}</TableCell>
                        <TableCell align="right">{row.middle_name}</TableCell>
                        <TableCell align="right">{row.last_name}</TableCell>
                        <TableCell align="right">{row.email}</TableCell>
                        <TableCell align="right">{row.password}</TableCell>
                        <TableCell align="right">{row.nationality}</TableCell>
                        <TableCell align="right">{row.notice}</TableCell>
                        <TableCell align="right">{row.phone}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>}


          </Box>


          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-around',
            mt: 4,
            gap: 2
          }}>

            <Button
              sx={{ flex: 1, minWidth: 68 }}
              variant="contained"
              onClick={() => {
                functionHandler.handleUploadTeacher();
              }}
            >
              Upload
            </Button>
            <Button
              sx={{ flex: 1, minWidth: 68 }}
              variant="contained"
              color="error"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Paper>

        <Snackbar
          open={info?.visible}
          autoHideDuration={6000}
          onClose={functionHandler.handleSnackbarClose}
        >
          <Alert
            onClose={functionHandler.handleSnackbarClose}
            severity={
              info?.status === true
                ? "success"
                : info?.status === false
                  ? "error"
                  : "info"
            }
            sx={{ width: "100%" }}
          >
            {info?.message || "!!!"}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}
